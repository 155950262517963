import { useSelector } from 'react-redux';
import { selectedBiteSubtitlesSelector } from '../store/bite/bite.selectors';
import { useMemo } from 'react';

const useLocalesApproved = () => {
  const subtitlesData = useSelector(selectedBiteSubtitlesSelector);

  const isVerifiedLocaleMap = useMemo(() => {
    if (!subtitlesData) {
      return {};
    }
    return subtitlesData?.reduce((acc, { locale, approved }) => {
      acc[locale] = acc[locale] === undefined ? approved : acc[locale];
      return acc;
    }, {});
  }, [subtitlesData]);

  return isVerifiedLocaleMap;
};

export default useLocalesApproved;
