import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getIsRtl } from '../../locale/i18n';
import { Loader } from '../shared';
import {
  knowledgeIsLoadingSelector,
  knowledgeRequestPromptSelector,
  knowledgeRelatedContentSelector,
  knowledgeTagsSearchDataCountSelector,
} from '../../store/knowledge/knowledge.selectors';
import BiteItem from '../bite/BiteItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { relatedContentCy, relatedContentItemCy } from '../../screens/Prompt/aiPrompt.constants';

interface IProps {
  orgId?: number;
  isPublicFeed?: boolean;
  withoutProgressIndication?: boolean;
  onBiteClick?: (biteId: number, subject: string) => void;
  onPlaylistClick?: (playlistId: number) => void;
}

const KnowledgeList: FC<IProps> = ({ withoutProgressIndication, onBiteClick }) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();

  const prompt = useSelector(knowledgeRequestPromptSelector);
  const { answer, searchRelated, answerRelated } = useSelector(knowledgeRelatedContentSelector);
  // const searchData = useSelector(knowledgeTagsSearchDataSelector);
  const isLoading = useSelector(knowledgeIsLoadingSelector);
  // const isSearchDataLoading = useSelector(knowledgeTagsSearchDataIsLoadingSelector);
  const searchCount = useSelector(knowledgeTagsSearchDataCountSelector);

  const relatedCount = (searchRelated?.length || 0) + (answerRelated?.length || 0);
  const dataLength = relatedCount; //+ (searchData?.length || 0);
  const hasMore = dataLength < relatedCount + searchCount;

  // const searchDisplayedData = useMemo(
  //   () =>
  //     searchData?.filter(
  //       (searchBiteShare) =>
  //         !searchRelated?.some((searchRelatedBiteShare) => searchRelatedBiteShare.id === searchBiteShare.id) &&
  //         !answerRelated?.some((answerRelatedBiteShare) => answerRelatedBiteShare.id === searchBiteShare.id),
  //     ),
  //   [searchData, searchRelated, answerRelated],
  // );

  // const handleNext = useCallback(() => {
  //   if (isSearchDataLoading) {
  //     return;
  //   }
  //   dispatch(fetchTagSearchData());
  // }, [dispatch, isSearchDataLoading]);

  const isEmptyList = !answerRelated?.length && !searchRelated?.length && !prompt.length; // || !searchData?.length);

  const LoaderComponent = useCallback(
    () => (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    ),
    [],
  );

  // show nothing if there is no data
  if (isEmptyList) {
    return null;
  }

  // show nothing if there is no answer and it's loading
  if (!answer?.length && isLoading) {
    return null;
  }

  return (
    <S.List
      scrollableTarget='app-container'
      dataLength={dataLength}
      // next={handleNext}
      hasMore={hasMore}
      loader={isLoading ? null : <LoaderComponent />}
    >
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <S.SectionTitle data-cy={relatedContentCy}>{t('knowledge.relevantBites')}</S.SectionTitle>
          {answerRelated &&
            answerRelated.map((bite) => (
              <BiteItem
                dataCy={relatedContentItemCy}
                bite={bite}
                withoutProgressIndication={withoutProgressIndication}
                onClick={onBiteClick}
                key={bite.id}
              />
            ))}
          {searchRelated &&
            !answerRelated.length &&
            searchRelated.map((bite) => (
              <BiteItem
                dataCy={relatedContentItemCy}
                bite={bite}
                withoutProgressIndication={withoutProgressIndication}
                onClick={onBiteClick}
                key={bite.id}
              />
            ))}
          {/*{searchDisplayedData &&*/}
          {/*  searchDisplayedData.map((bite) => (*/}
          {/*    <BiteItem*/}
          {/*      dataCy={searchContentItemCy}*/}
          {/*      bite={bite}*/}
          {/*      withoutProgressIndication={withoutProgressIndication}*/}
          {/*      onClick={onBiteClick}*/}
          {/*      key={bite.id}*/}
          {/*    />*/}
          {/*  ))}*/}
        </>
      )}
    </S.List>
  );
};

const S: any = {
  List: styled(InfiniteScroll)`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 23px;
    padding-bottom: 20px;
    ${() => (getIsRtl() ? 'direction: rtl;' : '')};
    writing-mode: horizontal-tb;
    margin-top: 32px;
  `,
  Divider: styled.div`
    grid-column: 1 / -1;
    margin-top: 10px;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
  SectionTitle: styled.div`
    grid-column: 1 / -1;
    font-size: 15px;
    font-weight: 400;
  `,
  LoaderWrapper: styled.div`
    margin: 20px;
  `,
};

export default KnowledgeList;
