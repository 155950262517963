import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import { PlaylistAwaitsModal } from './components/PlaylistAwaitsModal';

import { MainContentWrapper } from '../../components/layout';
import { fetchSelectedPlaylistRequest, resetSelectedPlaylist } from '../../store/playlist/playlist.actions';
import BiteList from '../../components/bite';
import { selectedPlaylistSelector } from '../../store/playlist/playlist.selectors';
import { Loader, StyledImageTiles } from '../../components/shared';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { useRedirectToHome } from '../../hooks/useRedirectToHome';
import SideMenu from '../../navigation/MainApp/components/SideMenu';
import MainAppHeader from '../../navigation/MainApp/components/Header';
import { getIsRtl } from '../../locale/i18n';
import MetaTitle from '../../components/MetaTitle';
import { isAuthenticatedSelector, authSelector } from '../../store/auth/auth.selectors';
import gtmTrack from '../../services/googleTagManager/track';
import useCustomAuth from '../../hooks/useCustomAuth';
import useIsInsideMsTeams from '../../hooks/useIsInsideMsTeams';
import useQueryParams from '../../hooks/useQueryParams';
import Coverimage from './components/CoverImage';
import { v4 as uuidv4 } from 'uuid';
import { log } from '../../store/tracking/tracking.slice';
import useRemoveQueryParams from '../../hooks/useRemoveQueryParams';
import { ReactComponent as HomeButton } from '../../assets/icons/home.svg';
import { playlistViewContainerCy } from './ViewPlaylist.constants';

const ViewPlaylist: FC = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { state = { withoutModal: false } } = history.location;
  const [open, setOpen] = useState<boolean>(!(state as { withoutModal: boolean }).withoutModal);
  const authenticated = useSelector(isAuthenticatedSelector);
  const user = useSelector(authSelector);
  const { isInsideMsTeams } = useIsInsideMsTeams();
  const { skipProfile, isFetchingOrgSettings } = useCustomAuth();
  const { queryParams } = useQueryParams();
  const isPreview = queryParams?.isPreview;

  const match: any = useRouteMatch();

  const { prefixedT } = useCustomTranslation('screens.ViewPlaylist');

  const { selectedPlaylist, isLoading } = useSelector(selectedPlaylistSelector);
  const isLoadingRef = useRef(isLoading);

  const processId = useMemo(() => uuidv4(), []);

  const withoutEmptyBites = useMemo(() => {
    return selectedPlaylist ? selectedPlaylist.bite_shares.filter((bite) => !bite.no_sections) : [];
  }, [selectedPlaylist]);

  const numOfCompletedBites = useMemo(() => {
    const completedBiteShares = withoutEmptyBites.filter(
      (biteShare) => biteShare.bite_share_user.bite_progress === 'done',
    );
    return completedBiteShares?.length || 0;
  }, [withoutEmptyBites]);

  const playlistUserOrg = useMemo(() => {
    return (
      selectedPlaylist &&
      user?.organizations?.find((org) => parseInt(org.id, 10) === parseInt(selectedPlaylist?.organization, 10))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations, selectedPlaylist?.organization]);

  useRemoveQueryParams(['timestamp']);
  useRedirectToHome({
    sharingMode: selectedPlaylist?.sharing_mode,
    userOrg: playlistUserOrg,
    processId,
  });

  const isLinear = selectedPlaylist?.is_linear;

  const playlistId = match.params.id;

  const isEmptyPlaylist = numOfCompletedBites === 0 && withoutEmptyBites.length === 0;

  useEffect(() => {
    history.replace({ ...history.location, state: {} });

    if (selectedPlaylist && Number(selectedPlaylist?.id) === Number(playlistId)) {
      return;
    }

    dispatch(fetchSelectedPlaylistRequest({ playlistId, processId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedPlaylist());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // need to track only when loading switched true -> false
    if (!isLoading && isLoadingRef.current) {
      gtmTrack('pageview', {
        page_title: 'viewPlaylist',
        bites_user_id: user?.id,
        playlist_id: playlistId,
        content_org_id: selectedPlaylist?.organization,
        sharing_mode: selectedPlaylist?.sharing_mode,
        creator: selectedPlaylist?.creator,
      });
    }

    isLoadingRef.current = isLoading;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (selectedPlaylist?.side_effects?.opted_in) {
      toast(t('components.optedInNotification'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaylist?.side_effects?.opted_in]);

  const isDisplayLoading =
    !selectedPlaylist ||
    isLoading ||
    isFetchingOrgSettings ||
    (!isInsideMsTeams &&
      selectedPlaylist?.sharing_mode !== 'anyone' &&
      !skipProfile &&
      !playlistUserOrg?.is_profile_complete);

  useEffect(() => {
    dispatch(
      log({
        event: 'ViewPlaylist: isDisplayLoading changed',
        processId,
        data: {
          isDisplayLoading,
          isLoading,
          selectedPlaylist,
          isFetchingOrgSettings,
          isInsideMsTeams,
          skipProfile,
          playlistUserOrg,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayLoading]);

  const handleHomeButtonClick = useCallback(() => {
    dispatch(log({ event: 'ViewPlaylist.handleHomeButtonClick' }));
    history.push('/playlists');
    gtmTrack('back_to_home_button', {});
  }, [dispatch, history]);

  const renderRight = useCallback(() => {
    return (
      <S.HomeButtonContainer onClick={handleHomeButtonClick}>
        <S.HomeButton />
      </S.HomeButtonContainer>
    );
  }, [handleHomeButtonClick]);

  if (isDisplayLoading) {
    return <Loader />;
  }

  const handleBiteClick = (biteId: number) => {
    history.push(`/bites/${biteId}?playlistId=${selectedPlaylist.id}${isPreview ? '&isPreview=1' : ''}`);
  };

  const { subject } = selectedPlaylist;

  return (
    <>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      <MetaTitle title={t('metaTitles.playlist', { name: subject })} />
      {authenticated && (
        <div>
          <SideMenu isOpen={isSideMenuOpen} onClose={() => setIsSideMenuOpen(false)} />
          <MainAppHeader
            onHamburgerClick={() => setIsSideMenuOpen(true)}
            isPreview={isPreview}
            renderRight={!isPreview && renderRight}
          />
        </div>
      )}
      <MainContentWrapper>
        <PlaylistAwaitsModal open={open} onClose={() => setOpen(false)} />

        <S.Container data-cy={playlistViewContainerCy}>
          <S.Top>
            {selectedPlaylist?.linked_cover_url ? (
              <Coverimage url={selectedPlaylist.linked_cover_url} />
            ) : (
              <StyledImageTiles.Container isQuiz={false}>
                {withoutEmptyBites.map((bite) => (
                  <StyledImageTiles.ImageTile key={bite.id} src={bite.cover_url} />
                ))}
              </StyledImageTiles.Container>
            )}
            <S.DetailsContainer>
              <S.Subject>{subject}</S.Subject>
              {isEmptyPlaylist ? (
                <S.BitesCount>{prefixedT('emptyPlaylist')}</S.BitesCount>
              ) : (
                <S.BitesCount>
                  {`${numOfCompletedBites}/${withoutEmptyBites.length} ${prefixedT('completed')}`}
                </S.BitesCount>
              )}
            </S.DetailsContainer>
          </S.Top>

          <BiteList bites={withoutEmptyBites} onBiteClick={handleBiteClick} isLinear={isLinear} />
        </S.Container>
      </MainContentWrapper>
    </>
  );
};

const S = {
  Container: styled.div``,

  Top: styled.div`
    display: flex;
    margin-bottom: 24px;
    padding-top: 30px;
    ${() =>
      getIsRtl() &&
      `
			flex-direction: row-reverse;
		`};
  `,

  DetailsContainer: styled.div`
    margin-left: 12px;
    padding: 5px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${() =>
      getIsRtl() &&
      `
			direction: rtl;
			text-align: right;
			margin-left: 0;
			margin-right: 12px;
            writing-mode: horizontal-tb;
		`};
  `,

  Subject: styled.h3`
    font-size: 24px;
    line-height: 28px;
    ${({ theme }) => css`
      color: ${theme.colors.darkPrimary};
    `}
  `,

  BitesCount: styled.p`
    font-size: 16px;
    line-height: 19px;
    ${({ theme }) => css`
      color: ${theme.colors.lightGray6};
    `}
  `,

  HomeButtonContainer: styled.div`
    width: 41px;
    height: 41px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.1);
    margin: 0 16px;
  `,
  HomeButton: styled(HomeButton)`
    width: 24px;
    height: 24px;
  `,
};

export default ViewPlaylist;
