import React, { FC, memo, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import LangsOverlay from '../../../../screens/viewBite/Intro/components/LangsOverlay';
import { ReactComponent as GlobeSVG } from '../../../../assets/icons/globe2.svg';
import useAlwaysTranslate from '../../../../hooks/useAlwaysTranslate';
import { useDispatch } from 'react-redux';
import { log } from '../../../../store/tracking/tracking.slice';
import { Loader } from '../../';
import { ISubtitles } from '../../../../types/bite';

const SubtitlesTranslations: FC<{
  currentLocale: string;
  isLoading: boolean;
  subtitles?: ISubtitles[];
  isShowLangsOverlay: boolean;
  subtitlesDisabled?: boolean;
  setIsShowLangsOverlay: (value: boolean) => void;
  onSelect: (props: { locale: string; isManually?: boolean }) => void;
}> = ({
  currentLocale,
  isLoading,
  subtitles,
  isShowLangsOverlay,
  subtitlesDisabled,
  setIsShowLangsOverlay,
  onSelect,
}) => {
  const dispatch = useDispatch();

  const { isAlwaysTranslate, toggleAlwaysTranslate, enableAlwaysTranslate, disableAlwaysTranslate } =
    useAlwaysTranslate();

  const enabledSubtitles = useMemo(() => subtitles?.find((x) => x.enabled), [subtitles]);

  const currentLocaleRef = useRef(currentLocale);
  const handleShowLangsOverlay = useCallback(() => {
    currentLocaleRef.current = currentLocale;

    setIsShowLangsOverlay(true);
  }, [currentLocale, setIsShowLangsOverlay]);

  const handleHideLangsOverlay = useCallback(() => {
    setIsShowLangsOverlay(false);
  }, [setIsShowLangsOverlay]);

  const handleToggleAlwaysTranslate = useCallback(async () => {
    dispatch(
      log({
        event: 'SubtitlesTranslations: handleToggleAlwaysTranslate',
      }),
    );

    toggleAlwaysTranslate(currentLocale);
  }, [currentLocale, dispatch, toggleAlwaysTranslate]);

  const handleSelect = useCallback(
    (newSelectedLocale: string) => {
      dispatch(
        log({
          event: 'SubtitlesTranslationsControls: handleSelect',
          data: { newSelectedLocale },
        }),
      );

      currentLocaleRef.current = newSelectedLocale;

      if (newSelectedLocale !== currentLocale && isAlwaysTranslate) {
        enableAlwaysTranslate(newSelectedLocale);
      }

      if (newSelectedLocale === enabledSubtitles?.locale && isAlwaysTranslate) {
        disableAlwaysTranslate();
      }

      onSelect({ locale: newSelectedLocale, isManually: true });
    },
    [
      currentLocale,
      disableAlwaysTranslate,
      dispatch,
      enableAlwaysTranslate,
      enabledSubtitles?.locale,
      isAlwaysTranslate,
      onSelect,
    ],
  );

  return (
    <>
      <S.Btn isActive={!subtitlesDisabled} onClick={handleShowLangsOverlay}>
        {isLoading && (
          <S.Loader>
            <Loader size='36px' />
          </S.Loader>
        )}
        {!isLoading && currentLocale && <S.Label>{currentLocale.toUpperCase()}</S.Label>}
        <GlobeSVG width='16px' height='16px' />
      </S.Btn>
      <LangsOverlay
        isVisible={isShowLangsOverlay}
        isAlwaysTranslate={isAlwaysTranslate}
        selectedLocale={currentLocale}
        defaultLocale={enabledSubtitles?.locale}
        onSelect={handleSelect}
        onClose={handleHideLangsOverlay}
        onToggleAlwaysTranslate={handleToggleAlwaysTranslate}
      />
    </>
  );
};
export default memo(SubtitlesTranslations);

export const S = {
  Btn: styled.div<{ isActive: boolean }>`
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    height: 28px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.7)};
    cursor: pointer;
    transition: opacity 150ms ease-in-out;

    &:hover {
      opacity: ${({ isActive }) => (isActive ? 1 : 0.8)};
    }
  `,
  Label: styled.div`
    margin-right: 4px;
    font-size: 17px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  Loader: styled.div`
    width: 32px;
  `,
};
