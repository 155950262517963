import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckSVG } from '../../../../assets/icons/check.svg';
import { createPortal } from 'react-dom';
import { bodyPortal } from '../../../../App';
import defaultTheme from '../../../../style/themes/defaultTheme';
import useTranslationOptions, { ILocale } from '../../../../hooks/useTranslationOptions';
import { ReactComponent as LoaderSVG } from '../../../../assets/loaders/loader-2.svg';
import { ReactComponent as VoiceoverSVG } from '../../../../assets/icons/voiceover.svg';
import useIsDisplayedIsVisible from '../../../../hooks/useIsDisplayedIsVisible';
import { useTranslation } from 'react-i18next';
import { getIsRtl } from '../../../../locale/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedBiteSelector,
  subtitlesDisabledSelector,
  translatedVoiceoversLocaleSelector,
  translateSubtitlesTranslatingIntoLocaleSelector,
} from '../../../../store/bite/bite.selectors';
import { setSubtitlesDisabled, translateVoiceovers } from '../../../../store/bite/bite.actions';
import { log } from '../../../../store/tracking/tracking.slice';
import { ReactComponent as CloseSVG } from '../../../../assets/icons/close2.svg';
import { organizationHasFeatureFlagSelector } from '../../../../store/profile/profile.selectors';
import { Features } from '../../../../utils/featureFlag/featureFlag.types';
import useLocalesApproved from '../../../../hooks/useLocalesApproved';

interface IProps {
  isVisible: boolean;
  isAlwaysTranslate: boolean;
  selectedLocale?: string;
  defaultLocale?: string;
  onClose: () => void;
  onSelect: (lang: string) => void;
  onToggleAlwaysTranslate?: () => void;
}
const LangsOverlay = ({
  isVisible: isVisibleProp,
  isAlwaysTranslate,
  selectedLocale,
  defaultLocale,
  onSelect,
  onClose,
  onToggleAlwaysTranslate,
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const subtitlesDisabled = useSelector(subtitlesDisabledSelector);
  const isLocalesVerifiedMap = useLocalesApproved();

  const { selectedBite } = useSelector(selectedBiteSelector);
  const voiceoverTranslationsFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId: parseInt(selectedBite?.orgid, 10),
      feature: Features.VOICEOVER_TRANSLATIONS,
    }),
  );

  const isRtl = getIsRtl();

  const [withAlwaysTranslate, setWithAlwaysTranslate] = useState(
    selectedLocale !== defaultLocale && !!onToggleAlwaysTranslate,
  );
  const translatedVoiceoversLocale = useSelector(translatedVoiceoversLocaleSelector);
  const translatingIntoLocale = useSelector(translateSubtitlesTranslatingIntoLocaleSelector);

  const [searchStr, setSearchStr] = useState('');

  const { isLoading, error, groupedOptions, loadOptions, localesWithVoiceover } = useTranslationOptions({
    searchStr,
    defaultLocale,
    selectedLocale,
  });
  const { isDisplayed, isVisible } = useIsDisplayedIsVisible(isVisibleProp, 250);

  const tryAgain = useCallback(
    (e) => {
      e.stopPropagation();
      loadOptions();
    },
    [loadOptions],
  );

  const handleToggleTranslatedVoiceoversLocale = useCallback(() => {
    dispatch(
      log({
        event: 'LangsOverlay: handleToggleTranslatedVoiceoversLocale',
      }),
    );

    onClose?.();

    if (translatedVoiceoversLocale) {
      dispatch(translateVoiceovers({ locale: null }));
      return;
    }

    dispatch(translateVoiceovers({ locale: translatingIntoLocale || selectedLocale }));
  }, [dispatch, onClose, selectedLocale, translatedVoiceoversLocale, translatingIntoLocale]);

  useEffect(() => {
    if (!isVisible && onToggleAlwaysTranslate && selectedLocale !== defaultLocale) {
      setWithAlwaysTranslate(true);
    }
  }, [onToggleAlwaysTranslate, isVisible, selectedLocale, defaultLocale]);

  const handleSelect = useCallback(
    (locale: string) => {
      onClose?.();

      if (selectedLocale === locale) {
        return;
      }

      onSelect(locale);
    },
    [onClose, onSelect, selectedLocale],
  );

  const handleToggleSubtitlesDisabled = useCallback(() => {
    const newSubtitlesDisabled = !subtitlesDisabled;

    dispatch(setSubtitlesDisabled(newSubtitlesDisabled));

    // close when disabling
    if (newSubtitlesDisabled) {
      onClose?.();
    }
  }, [dispatch, onClose, subtitlesDisabled]);

  const withVoiceoverTranslations =
    voiceoverTranslationsFeatureFlag && localesWithVoiceover.has(selectedLocale) && defaultLocale !== selectedLocale;

  const handleSearch = useCallback((e) => {
    setSearchStr(e.target.value);
  }, []);
  const handleClearSearchStr = useCallback(() => {
    setSearchStr('');
  }, []);

  const [minHeight, setMinHeight] = useState(0);
  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const rect = panelRef.current?.getBoundingClientRect();
    setMinHeight(rect?.height || 0);
  }, [groupedOptions]);

  useEffect(() => {
    if (isDisplayed) {
      return;
    }

    if (subtitlesDisabled) {
      setMinHeight(0);
    }

    setSearchStr('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisplayed]);

  const Overlay = (
    <S.Container isDisplayed={isDisplayed} isVisible={isVisible}>
      <S.Backdrop onClick={onClose} />
      <S.Panel minHeight={minHeight} ref={panelRef}>
        <S.TitleContainer>
          <S.Title>{t('components.translations.translateSubtitles')}</S.Title>
          <S.ActiveButton isActive={!subtitlesDisabled} onClick={handleToggleSubtitlesDisabled}>
            {subtitlesDisabled ? t('components.translations.turnOn') : t('components.translations.turnOff')}
          </S.ActiveButton>
        </S.TitleContainer>

        {subtitlesDisabled ? (
          <S.SubtitlesDisabled>{t('components.translations.subtitlesAreDisabled')}</S.SubtitlesDisabled>
        ) : (
          <>
            {withAlwaysTranslate && (
              <>
                <S.Row isRtl={isRtl} onClick={onToggleAlwaysTranslate}>
                  <S.RowLabel>{t('components.translations.alwaysTranslate')}</S.RowLabel>
                  <S.Grow />
                  <S.IconContainer>
                    <CheckSVG
                      width={14}
                      height={14}
                      fill={isAlwaysTranslate ? defaultTheme.colors.lightGray2 : 'transparent'}
                    />
                  </S.IconContainer>
                </S.Row>
              </>
            )}
            {withVoiceoverTranslations && (
              <>
                <S.Row isRtl={isRtl} onClick={handleToggleTranslatedVoiceoversLocale}>
                  <S.IconContainer marginRight>
                    <VoiceoverSVG width={14} height={14} stroke={defaultTheme.colors.lightGray2} />
                  </S.IconContainer>
                  <S.RowLabel>{t('components.translations.translateAudioAsWell')}</S.RowLabel>
                  <S.Grow />
                  <S.IconContainer>
                    <CheckSVG
                      width={14}
                      height={14}
                      fill={translatedVoiceoversLocale ? defaultTheme.colors.lightGray2 : 'transparent'}
                    />
                  </S.IconContainer>
                </S.Row>
              </>
            )}
            <S.SearchContainer>
              <S.SearchInput
                placeholder={t('components.translations.searchInputPlaceHolder')}
                value={searchStr}
                onChange={handleSearch}
              />
              <S.CloseContainer hasValue={!!searchStr} onClick={handleClearSearchStr}>
                <CloseSVG width='16px' height='16px' fill={defaultTheme.colors.darkGray9} />
              </S.CloseContainer>
            </S.SearchContainer>
            {/* {(withAlwaysTranslate || withVoiceoverTranslations) && <S.Separator marginTop />} */}
            <S.LangsContainer>
              {isLoading && (
                <S.LoadingContainer>
                  <LoaderSVG width={20} height={20} />
                </S.LoadingContainer>
              )}
              {error && (
                <S.Error>
                  {t('components.translations.somethingWentWrong')}{' '}
                  <S.LinkBtn onClick={tryAgain}>{t('components.translations.pleaseTryAgain')}</S.LinkBtn>
                </S.Error>
              )}
              {groupedOptions.map((group, index) => (
                <React.Fragment key={group.label}>
                  {!group.options && (
                    <Row
                      option={group as ILocale}
                      isOrgWithVoiceovers={voiceoverTranslationsFeatureFlag}
                      onSelect={handleSelect}
                      isVerified={isLocalesVerifiedMap[group.locale]}
                    />
                  )}
                  {group.options?.map((option) => (
                    <Row
                      option={option}
                      isOrgWithVoiceovers={voiceoverTranslationsFeatureFlag}
                      onSelect={handleSelect}
                      key={option.locale}
                      isVerified={isLocalesVerifiedMap[option.locale]}
                    />
                  ))}
                  {index < groupedOptions.length - 1 && <S.Separator marginTop marginBottom />}
                </React.Fragment>
              ))}
            </S.LangsContainer>
          </>
        )}
      </S.Panel>
    </S.Container>
  );

  return createPortal(Overlay, bodyPortal.current);
};
export default memo(LangsOverlay);

const Row = ({
  option,
  isOrgWithVoiceovers,
  onSelect,
  isVerified,
}: {
  option: ILocale;
  isOrgWithVoiceovers: boolean;
  onSelect: (option: string) => void;
  isVerified?: boolean;
}) => {
  const { t } = useTranslation();
  const handleSelect = useCallback(() => {
    onSelect(option.locale);
  }, [option, onSelect]);

  return (
    <S.Row onClick={handleSelect} key={option.locale}>
      <S.RowLabel>{option.label}</S.RowLabel>
      {isOrgWithVoiceovers && option.voiceover && (
        <S.IconContainer marginLeft>
          <VoiceoverSVG width={14} height={14} stroke={defaultTheme.colors.lightGray2} />
        </S.IconContainer>
      )}
      {isVerified && <S.VerifiedText>{t('locales.verified')}</S.VerifiedText>}
      {option.isSelected && (
        <>
          <S.Grow />
          <S.Dot />
        </>
      )}
    </S.Row>
  );
};

//
const S = {
  Container: styled.div<{ isDisplayed: boolean; isVisible: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    display: ${({ isDisplayed }) => (isDisplayed ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    padding: 24px;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.lightGray2};
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
    transform: scale(${({ isVisible }) => (isVisible ? '1' : '0.8')});
    transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
    cursor: default;
  `,
  Backdrop: styled.div`
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  `,
  Panel: styled.div<{
    minHeight: number;
  }>`
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 350px;
    min-height: ${({ minHeight }) => minHeight}px;
    max-height: 80vh;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.darkGray10}};
    box-shadow: 0px 4px 4px 0px ${({ theme }) => theme.colors.transparentBlack2};
    overflow: hidden;
  `,
  TitleContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    padding-right: 16px;
  `,
  Title: styled.div`
    display: flex;
    align-items: center;
    padding: 14px 24px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  ActiveButton: styled.div<{ isActive }>`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    opacity: ${({ isActive }) => (isActive ? 0.7 : 0.5)};
    cursor: pointer;
    transition: opacity 150ms ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  `,
  SubtitlesDisabled: styled.div`
    display: flex;
    align-items: center;
    padding: 8px 24px 20px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.lightGray2};
  `,
  SearchContainer: styled.div`
    position: relative;
    padding: 8px;
  `,
  SearchInput: styled.input`
    padding: 4px 16px;
    width: 100%;
    color: ${({ theme }) => theme.colors.lightGray2};
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    line-height: 24px;
    border: 1px solid ${({ theme, value }) => (value ? theme.colors.darkGray9 : theme.colors.darkGray11)};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.darkGray11};
    outline: none;
    transition: border-color 150ms ease-in-out;

    &::placeholder {
      color: ${({ theme }) => theme.colors.darkGray7};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.darkGray9};
    }
  `,
  CloseContainer: styled.div<{
    hasValue?: boolean;
  }>`
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    display: flex;
    align-items: center;
    padding: 1px 10px 0px 8px;
    cursor: pointer;
    opacity: ${({ hasValue }) => (hasValue ? 1 : 0)};
    transition: opacity 150ms ease-in-out;
  `,
  LoadingContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  `,
  LangsContainer: styled.div`
    flex: 1;
    overflow: auto;
  `,
  Row: styled.div<{ isRtl?: boolean }>`
    display: flex;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    padding: 0 24px;
    height: 42px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.darkGray9};
    }
  `,
  RowLabel: styled.div`
    display: flex;
    align-items: center;
    height: 44px;
    cursor: pointer;
  `,
  Grow: styled.div`
    flex-grow: 1;
  `,
  Separator: styled.div<{
    marginTop?: boolean;
    marginBottom?: boolean;
  }>`
    ${({ marginTop }) => (marginTop ? 'margin-top: 8px;' : '')}
    ${({ marginBottom }) => (marginBottom ? 'margin-bottom: 8px;' : '')}
    height: 1px;
    background-color: ${({ theme }) => theme.colors.darkGray11};
  `,
  IconContainer: styled.div<{ marginLeft?: boolean; marginRight?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ marginLeft }) => (marginLeft ? 'margin-left: 8px;' : '')}
    ${({ marginRight }) => (marginRight ? 'margin-right: 8px;' : '')}
    cursor: pointer;
  `,
  Dot: styled.div`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  Error: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    color: ${({ theme }) => theme.colors.white};
  `,
  LinkBtn: styled.span`
    text-decoration: underline;
    cursor: pointer;
  `,
  VerifiedText: styled.div`
    margin: 1px 8px 0;
    color: ${({ theme }) => theme.colors.green2};
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  `,
};
