import { all, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  setGeofenceContentInfo,
  setGeofenceData,
  setGeofenceModalOpened,
  setIsContentNotAvailable,
  startPolling,
} from './geofence.slice';
import { log } from '../tracking/tracking.slice';
import {
  geofenceContentInfoSelector,
  geofenceGeolocationSelector,
  isGeofenceModalOpenedSelector,
} from './geofence.selectors';
import { EContentType } from './geofence.types';
import { getById as getBiteById } from '../api/calls/bite.calls';
import { getById as getPlaylistById } from '../api/calls/playlist.calls';
import { setBiteResultSaga } from '../bite/bite.saga';
import { setPlaylistResult } from '../playlist/playlist.saga';
import { getQuiz } from '../api/calls/quiz.calls';
import { setQuizResultSaga } from '../quiz/quiz.saga';
import history from '../../navigation/history';
import { getOrganizationFeatureFlags } from '../profile/profile.actions';

const POLLING_INTERVAL = window.Cypress ? 500 : 10000;

function* geofencePollingSaga() {
  const contentInfo = yield select(geofenceContentInfoSelector);
  const isGeofenceModalOpened = yield select(isGeofenceModalOpenedSelector);

  if (!contentInfo || !isGeofenceModalOpened) {
    return;
  }

  yield put(
    log({
      event: 'geofencePollingSaga',
      processId: contentInfo.processId,
      data: {
        type: contentInfo.type,
        id: contentInfo.id,
      },
    }),
  );

  while (true) {
    // Polling logic
    try {
      if (contentInfo.type === EContentType.bite) {
        yield getBiteWithGeoDataSaga();
      } else if (contentInfo.type === EContentType.playlist) {
        yield getPlaylistWithGeoData();
      } else if (contentInfo.type === EContentType.quiz) {
        yield getQuizWithGeoData();
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      if (e.response && e.response.status === 403) {
        const geoData = yield select(geofenceGeolocationSelector);
        const event = geoData.latitude
          ? 'geofencePollingSaga: content not available (with geodata)'
          : 'geofencePollingSaga: content not available (without geodata)';
        yield put(
          log({
            event,
            processId: contentInfo.processId,
            data: {
              type: contentInfo.type,
              id: contentInfo.id,
              geoData,
            },
          }),
        );
        yield put(setIsContentNotAvailable(true));
      }
    }
    yield delay(POLLING_INTERVAL);
  }
}

function* getBiteWithGeoDataSaga() {
  const { processId, id } = yield select(geofenceContentInfoSelector);
  const geoData = yield select(geofenceGeolocationSelector);

  const event = geoData.latitude
    ? 'geofence.saga getBiteWithGeoDataSaga: start (with geodata)'
    : 'geofence.saga getBiteWithGeoDataSaga: start (without geodata)';

  yield put(
    log({
      event,
      processId,
      data: { biteId: id, geoData },
    }),
  );

  const headers = yield getHeadersSaga();

  const { data: bite } = yield getBiteById(
    id,
    {
      extend: ['enhancements', 'subtitles', 'sections'],
    },
    headers,
  );

  const resultEvent = geoData.latitude
    ? 'geofence.saga getBiteWithGeoDataSaga: result (with geodata)'
    : 'geofence.saga getBiteWithGeoDataSaga: result (without geodata)';

  yield put(
    log({
      event: resultEvent,
      processId,
      data: { bite, geoData },
    }),
  );

  yield put(getOrganizationFeatureFlags(bite.orgid));

  yield setBiteResultSaga({ bite });
  history.push(`/bites/${id}`);
  yield put(setGeofenceContentInfo(null));
  yield put(setGeofenceModalOpened(false));
}

function* getPlaylistWithGeoData() {
  const { processId, id } = yield select(geofenceContentInfoSelector);
  const geoData = yield select(geofenceGeolocationSelector);

  const event = geoData.latitude
    ? 'geofence.saga getPlaylistWithGeoData: start (with geodata)'
    : 'geofence.saga getPlaylistWithGeoData: start (without geodata)';

  yield put(
    log({
      event,
      processId,
      data: { playlistId: id, geoData },
    }),
  );

  const headers = yield getHeadersSaga();

  const { data: playlist } = yield getPlaylistById(id, headers);

  const resultEvent = geoData.latitude
    ? 'geofence.saga getPlaylistWithGeoData: result (with geodata)'
    : 'geofence.saga getPlaylistWithGeoData: result (without geodata)';

  yield put(
    log({
      event: resultEvent,
      processId,
      data: { playlist, geoData },
    }),
  );

  yield put(getOrganizationFeatureFlags(playlist.organization));

  yield setPlaylistResult({ playlist });
  history.push(`/playlists/${id}`);
  yield put(setGeofenceContentInfo(null));
  yield put(setGeofenceModalOpened(false));
}

function* getQuizWithGeoData() {
  const { processId, id } = yield select(geofenceContentInfoSelector);
  const geoData = yield select(geofenceGeolocationSelector);

  const event = geoData.latitude
    ? 'geofence.saga getQuizWithGeoData: start (with geodata)'
    : 'geofence.saga getQuizWithGeoData: start (without geodata)';
  yield put(
    log({
      event,
      processId,
      data: { quizId: id, geoData },
    }),
  );

  const headers = yield getHeadersSaga();

  const { data: quiz } = yield getQuiz(id, headers);

  const resultEvent = geoData.latitude
    ? 'geofence.saga getQuizWithGeoData: result (with geodata)'
    : 'geofence.saga getQuizWithGeoData: result (without geodata)';
  yield put(
    log({
      event: resultEvent,
      processId,
      data: { quiz, geoData },
    }),
  );

  yield put(getOrganizationFeatureFlags(quiz.organization));

  yield setQuizResultSaga({ quizId: id, quiz, processId });
  history.push(`/quiz/${id}`);
  yield put(setGeofenceContentInfo(null));
  yield put(setGeofenceModalOpened(false));
}

function* getHeadersSaga() {
  const { latitude, longitude, accuracy } = yield select(geofenceGeolocationSelector);
  const headers = {
    'x-geofence': true,
  };

  if (latitude && longitude && accuracy) {
    headers['X-Bites-Location-Lon'] = longitude;
    headers['X-Bites-Location-Lat'] = latitude;
    headers['X-Bites-Location-Accuracy'] = accuracy;
  }

  return headers;
}

export default function* geofenceSaga() {
  yield all([takeLatest([startPolling, setGeofenceModalOpened, setGeofenceData], geofencePollingSaga)]);
}
