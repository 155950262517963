import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as GlobeSVG } from '../../../../assets/icons/globe.svg';
import { ReactComponent as LoaderSVG } from '../../../../assets/loaders/loader-2.svg';
import LangsOverlay from './LangsOverlay';
import useTranslationOptions from '../../../../hooks/useTranslationOptions';

import useAlwaysTranslate from '../../../../hooks/useAlwaysTranslate';
import Popover from './Popover';
import { useTranslation } from 'react-i18next';
import { log } from '../../../../store/tracking/tracking.slice';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { bodyPortal } from '../../../../App';
import { getIsRtl } from '../../../../locale/i18n';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { currentBiteLocaleSelector } from '../../../../store/bite/bite.selectors';

interface IProps {
  withDisplayAnimation;
  currentLocale: string | null;
  enabledLocale: string | null;
  isVisible: boolean;
  isLoading: boolean;
  isError: boolean;
  translatingIntoLocale: string | null;
  onSelect: (props: { locale: string; isManually?: boolean; withSetCurrentLocale?: boolean }) => void;
}

const SubtitlesAutoTranslationsControls = ({
  withDisplayAnimation,
  currentLocale,
  enabledLocale,
  isVisible: isVisibleProp,
  isLoading,
  isError,
  translatingIntoLocale,
  onSelect,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMountedRef = useIsMounted();

  const [isVisible, setIsVisible] = useState(false);
  const [isShowLangsOverlay, setIsShowLangsOverlay] = useState(false);
  const [isErrorDismissed, setIsErrorDismissed] = useState(true);

  const currentTranslate = useSelector(currentBiteLocaleSelector);

  const containerRef = useRef();

  const {
    initialLocale,
    initialLocaleLabel,
    isAlwaysTranslate,
    isInitialTranslationDone,
    enableAlwaysTranslate,
    disableAlwaysTranslate,
    toggleAlwaysTranslate,
  } = useAlwaysTranslate();

  const [selectedLocale, setSelectedLocale] = useState(
    currentTranslate || (isInitialTranslationDone ? currentLocale : initialLocale),
  );

  const { options } = useTranslationOptions({
    defaultLocale: enabledLocale,
    selectedLocale: currentLocale,
  });

  const getLocaleLabel = useCallback(
    (locale) => {
      const localeOption = options?.find((option) => option.locale === locale);
      return localeOption?.label || t('components.translations.original');
    },
    [options, t],
  );

  const displayLocaleLabel = useMemo(() => {
    if (isError) {
      return getLocaleLabel(currentLocale);
    }

    if (selectedLocale === initialLocale && initialLocaleLabel) {
      return initialLocaleLabel;
    }

    return getLocaleLabel(selectedLocale);
  }, [isError, selectedLocale, initialLocale, initialLocaleLabel, getLocaleLabel, currentLocale]);

  const displayIsError = isError && !isErrorDismissed;

  const logContext = useMemo(
    () => ({
      currentLocale,
      isLoading,
      isError,
      translatingIntoLocale,
      isVisible,
      isVisibleProp,
      isShowLangsOverlay,
      isAlwaysTranslate,
      displayIsError,
    }),
    [
      currentLocale,
      isLoading,
      isError,
      translatingIntoLocale,
      isVisible,
      isVisibleProp,
      isShowLangsOverlay,
      isAlwaysTranslate,
      displayIsError,
    ],
  );

  const handleSelect = useCallback(
    (newSelectedLocale: string) => {
      setSelectedLocale(newSelectedLocale);

      if (isAlwaysTranslate) {
        if (newSelectedLocale === enabledLocale) {
          disableAlwaysTranslate();
        } else {
          enableAlwaysTranslate(newSelectedLocale);
        }
      }

      setIsErrorDismissed(false);
      // don't set isManually here because we don't want to change this component to controls
      onSelect({ locale: newSelectedLocale });
    },
    [disableAlwaysTranslate, enableAlwaysTranslate, enabledLocale, isAlwaysTranslate, onSelect],
  );

  const handleSelectFromList = useCallback(
    (newSelectedLocale: string) => {
      dispatch(
        log({
          event: 'SubtitlesAutoTranslationsControls: handleSelectFromList',
          data: { ...logContext, newSelectedLocale },
        }),
      );

      handleSelect(newSelectedLocale);
    },
    [dispatch, handleSelect, logContext],
  );

  const isErrorClickedRef = useRef(false);
  const handleRetry = useCallback(() => {
    isErrorClickedRef.current = true;

    dispatch(
      log({
        event: 'SubtitlesAutoTranslationsControls: handleRetry',
        data: logContext,
      }),
    );

    handleSelect(selectedLocale);
  }, [dispatch, handleSelect, logContext, selectedLocale]);

  const handleShowLangsOverlay = useCallback(() => {
    setIsShowLangsOverlay(true);
  }, []);

  const handleHideLangsOverlay = useCallback(() => {
    setIsShowLangsOverlay(false);
  }, []);

  const handleToggleAlwaysTranslate = useCallback(async () => {
    dispatch(
      log({
        event: 'SubtitlesAutoTranslationsControls: handleToggleAlwaysTranslate',
        data: logContext,
      }),
    );

    toggleAlwaysTranslate(currentLocale);
  }, [dispatch, logContext, toggleAlwaysTranslate, currentLocale]);

  useEffect(() => {
    const run = async () => {
      await new Promise((resolve) => setTimeout(resolve, withDisplayAnimation ? 500 : 0));

      if (!isMountedRef.current) {
        return;
      }

      dispatch(
        log({
          event: 'SubtitlesAutoTranslationsControls: show',
          data: logContext,
        }),
      );

      setIsVisible(true);
    };

    if (!currentTranslate && !isInitialTranslationDone) {
      setIsErrorDismissed(false);
      onSelect({
        locale: initialLocale,
        // withSetCurrentLocale: false
      });
    }

    run();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isError) {
      isErrorClickedRef.current = false;
    }
  }, [isError]);

  useEffect(() => {
    if (!isError) {
      return;
    }

    const dismissError = () => {
      const isErrorClicked = isErrorClickedRef.current;
      isErrorClickedRef.current = false;

      if (isErrorClicked) {
        return;
      }

      setIsErrorDismissed(true);
    };

    document.addEventListener('click', dismissError);

    return () => {
      document.removeEventListener('click', dismissError);
    };
  }, [isError]);

  return (
    <>
      {createPortal(
        <S.Container
          withDisplayAnimation={withDisplayAnimation}
          isVisible={isVisible && isVisibleProp}
          onClick={handleShowLangsOverlay}
          ref={containerRef}
        >
          <S.Globe>
            <GlobeSVG width={17} height={17} />
          </S.Globe>
          <S.Text>
            {isLoading
              ? t('components.translations.translatingSubtitlesInto')
              : t('components.translations.subtitlesTranslatedInto')}{' '}
            {displayLocaleLabel}
          </S.Text>
          <S.Loader isLoading={isLoading}>
            <LoaderSVG width={20} height={20} />
          </S.Loader>
        </S.Container>,
        bodyPortal.current,
      )}

      <LangsOverlay
        isVisible={isShowLangsOverlay && isVisibleProp}
        isAlwaysTranslate={isAlwaysTranslate}
        selectedLocale={currentLocale}
        defaultLocale={enabledLocale}
        onSelect={handleSelectFromList}
        onClose={handleHideLangsOverlay}
        onToggleAlwaysTranslate={handleToggleAlwaysTranslate}
      />

      <Popover targetRef={containerRef} isVisible={displayIsError && isVisibleProp} onClick={handleRetry}>
        <S.Error>{t('components.translations.translationFailed')}</S.Error>
      </Popover>
    </>
  );
};
export default memo(SubtitlesAutoTranslationsControls);

const S = {
  Container: styled.div<{ withDisplayAnimation: boolean; isVisible: boolean }>`
    position: fixed;
    left: 50%;
    bottom: 8px;
    display: flex;
    flex-direction: ${() => (getIsRtl() ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.lightGray21};
    font-size: 14px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateX(-50%);
    transition: all ${({ withDisplayAnimation }) => (withDisplayAnimation ? 300 : 0)}ms ease-in-out;
    cursor: pointer;
  `,
  Content: styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  Globe: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    fill: ${({ theme }) => theme.colors.lightGray21};
  `,
  Text: styled.div`
    margin: 0px 4px;
    flex-shrink: 0;
  `,
  Loader: styled.div<{ isLoading: boolean }>`
    opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
    transition: all 300ms ease-in-out;
  `,
  Error: styled.span`
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  `,
};
