import { cloneDeep } from 'lodash';
import localStorage from '../../utils/localStorage';

import BiteActionTypes from './bite.types';

import { IBite, ISubtitles, ITranslatedVoiceover } from '../../types/bite';

const initialState = {
  bites: {
    bites: [] as any[],
    isLoading: false,
    isLoadingNext: false,
    error: '',
    filter: '',
    totalBites: 0,
    nextPage: 1,
  },
  selectedBite: {
    selectedBite: null as IBite, // this is bite share + sections
    isLoading: false,
    error: '',
    canPlay: true,
    latestChangedCommentIndex: null,
  },
  addComment: {
    isLoading: false,
    error: '',
  },
  deleteComment: {
    isLoading: false,
  },
  commentSuggestion: {
    comment: null,
    isLoading: false,
  },
  subtitlesDisabled: false,
  translateSubtitles: {
    translatingIntoLocale: null,
    data: null as ISubtitles,
    isLoading: false,
    error: null,
  },
  translatedVoiceoversLocale: null,
  translateVoiceoversByLocale: {} as Record<
    string,
    {
      pendingAudio: ITranslatedVoiceover | null;
      audio: ITranslatedVoiceover | null;
      isLoading: false;
      error: null;
    }
  >,
  alwaysTranslateToLocale: {
    initialIsAlwaysTranslate: false,
    initialLocale: null as string | null,
    initialLocaleLabel: null as string | null,
    isInitialTranslationDone: false,
    isAlwaysTranslate: false,
  },
  currentLocale: null as string | null,
  isSubtitlesTranslatedManually: false,
};

const biteReducer = (state = initialState, action: any): typeof initialState => {
  switch (action.type) {
    case BiteActionTypes.BITE_QUERY_REQUEST: {
      return {
        ...state,
        bites: {
          ...state.bites,
          isLoading: true,
          error: '',
          nextPage: 1,
        },
      };
    }

    case BiteActionTypes.BITE_QUERY_SUCCESS: {
      const { bites, totalBites } = action.payload;
      return {
        ...state,
        bites: {
          ...state.bites,
          bites,
          isLoading: false,
          totalBites,
          nextPage: 2,
        },
      };
    }

    case BiteActionTypes.BITE_QUERY_ERROR: {
      return {
        ...state,
        bites: {
          ...state.bites,
          isLoading: false,
          error: action.payload,
        },
      };
    }

    case BiteActionTypes.BITE_LOAD_NEXT: {
      return {
        ...state,
        bites: {
          ...state.bites,
          isLoadingNext: true,
          error: '',
        },
      };
    }

    case BiteActionTypes.BITE_LOAD_NEXT_SUCCESS: {
      const bites = state.bites.bites.concat(action.payload);
      return {
        ...state,
        bites: {
          ...state.bites,
          isLoadingNext: false,
          bites,
          nextPage: state.bites.nextPage + 1,
        },
      };
    }

    case BiteActionTypes.FETCH_SELECTED_BITE_REQUEST: {
      const locale = localStorage.getItem('alwaysTranslateToLocale') || null;

      return {
        ...state,
        selectedBite: {
          ...initialState.selectedBite,
          isLoading: true,
          error: '',
        },
        commentSuggestion: initialState.commentSuggestion,
        subtitlesDisabled: initialState.subtitlesDisabled,
        translateSubtitles: initialState.translateSubtitles,
        translatedVoiceoversLocale: initialState.translatedVoiceoversLocale,
        translateVoiceoversByLocale: initialState.translateVoiceoversByLocale,
        alwaysTranslateToLocale: {
          initialIsAlwaysTranslate: !!locale,
          initialLocale: locale,
          initialLocaleLabel: localStorage.getItem('alwaysTranslateToLocaleLabel') || null,
          isInitialTranslationDone: false,
          isAlwaysTranslate: !!locale,
        },
      };
    }
    case BiteActionTypes.FETCH_SELECTED_BITE_SUCCESS:
      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          selectedBite: {
            ...action.payload,
            bite_sections: [
              action.payload.bite_sections?.find((section) => section?.type === 'intro'),
              action.payload.bite_sections?.find((section) => section?.type === 'question'),
              action.payload.bite_sections?.find((section) => section?.type === 'summary'),
              action.payload.bite_sections?.find((section) => section?.type === 'content'),
            ].filter((section) => section),
          },
          isLoading: false,
        },
      };

    case BiteActionTypes.RESET_SELECTED_BITE:
      return {
        ...state,
        selectedBite: initialState.selectedBite,
        commentSuggestion: initialState.commentSuggestion,
        subtitlesDisabled: initialState.subtitlesDisabled,
        translateSubtitles: initialState.translateSubtitles,
        translatedVoiceoversLocale: initialState.translatedVoiceoversLocale,
        translateVoiceoversByLocale: initialState.translateVoiceoversByLocale,
        alwaysTranslateToLocale: initialState.alwaysTranslateToLocale,
      };

    case BiteActionTypes.SET_IS_ALWAYS_TRANSLATE:
      return {
        ...state,
        alwaysTranslateToLocale: {
          ...state.alwaysTranslateToLocale,
          isAlwaysTranslate: action.payload,
        },
      };

    case BiteActionTypes.ADD_COMMENT_REQUEST:
      return {
        ...state,
        addComment: {
          ...state.addComment,
          isLoading: true,
          error: '',
        },
      };

    case BiteActionTypes.DELETE_COMMENT_REQUEST:
      return {
        ...state,
        deleteComment: {
          ...state.deleteComment,
          isLoading: true,
        },
      };
    case BiteActionTypes.ADD_COMMENT_SUCCESS: {
      const { comment, type } = action.payload;
      let newComments = [];
      let newCommentIndex: number | null = null;

      if (type === 'biteshare') {
        newComments = [...state.selectedBite.selectedBite.comments, comment];
        newCommentIndex = newComments.length - 1;
      } else {
        newComments = [...state.selectedBite.selectedBite.comments].map((item, index) => {
          if (item.id === comment.object_id) {
            newCommentIndex = index;
            return {
              ...item,
              related_comments: [...item.related_comments, comment],
            };
          } else {
            return item;
          }
        });
      }
      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          selectedBite: {
            ...state.selectedBite.selectedBite,
            comments: newComments,
          },
          latestChangedCommentIndex: newCommentIndex,
        },
        addComment: {
          ...state.addComment,
          isLoading: false,
        },
      };
    }

    case BiteActionTypes.DELETE_COMMENT_SUCCESS: {
      const { commentId, type } = action.payload;
      let commentsList = [];

      if (type === 'biteshare') {
        commentsList = state.selectedBite.selectedBite.comments.filter((item) => item.id !== commentId);
      } else {
        commentsList = [...state.selectedBite.selectedBite.comments].map((item) => {
          return {
            ...item,
            related_comments: item.related_comments.filter((rep1) => rep1.id !== commentId),
          };
        });
      }

      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          selectedBite: {
            ...state.selectedBite.selectedBite,
            comments: commentsList,
          },
        },
        deleteComment: {
          ...state.deleteComment,
          isLoading: false,
        },
      };
    }
    case BiteActionTypes.ENABLE_VIDEO_PLAYING:
      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          canPlay: true,
        },
      };
    case BiteActionTypes.DISABLE_VIDEO_PLAYING:
      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          canPlay: false,
        },
      };
    case BiteActionTypes.MULTIPLE_CHOICES_QUATION_SUCCESS_BITE: {
      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          selectedBite: {
            ...state.selectedBite.selectedBite,
            bite_share_user: {
              ...state.selectedBite.selectedBite.bite_share_user,
              choices: cloneDeep(action.payload.formattedBiteShareChoices),
            },
            bite_sections: cloneDeep(action.payload.formattedSections),
          },
        },
      };
    }
    case BiteActionTypes.SET_COMMENT_SUGGESTION: {
      return {
        ...state,
        commentSuggestion: {
          ...state.commentSuggestion,
          comment: action.payload.comment,
          isLoading: false,
        },
      };
    }
    case BiteActionTypes.SET_COMMENT_SUGGESTION_IS_LOADING: {
      return {
        ...state,
        commentSuggestion: {
          ...state.commentSuggestion,
          isLoading: action.payload,
        },
      };
    }

    // case BiteActionTypes.SET_INITIAL_IS_AWLAYS_TRANSLATE: {
    //   return {
    //     ...state,
    //     alwaysTranslateToLocale: {
    //       ...state.alwaysTranslateToLocale,
    //       // initialIsAlwaysTranslate: action.payload,
    //     },
    //   };
    // }

    case BiteActionTypes.SET_SUBTITLES_DISABLED: {
      return {
        ...state,
        subtitlesDisabled: action.payload,
      };
    }

    case BiteActionTypes.TRANSLATE_SUBTITLES: {
      const { locale, isManually } = action.payload;
      return {
        ...state,
        translatedVoiceoversLocale: locale === state.translatedVoiceoversLocale ? locale : null,
        isSubtitlesTranslatedManually: !!isManually,
      };
    }

    case BiteActionTypes.TRANSLATE_SUBTITLES_REQUEST: {
      return {
        ...state,
        translateSubtitles: {
          ...state.translateSubtitles,
          translatingIntoLocale: action.payload,
          isLoading: true,
          error: null,
        },
      };
    }

    case BiteActionTypes.TRANSLATE_SUBTITLES_SUCCESS: {
      return {
        ...state,
        translateSubtitles: {
          translatingIntoLocale: null,
          data: action.payload,
          isLoading: false,
          error: null,
        },
        alwaysTranslateToLocale: {
          ...state.alwaysTranslateToLocale,
          isInitialTranslationDone: true,
        },
      };
    }

    case BiteActionTypes.SET_TRANSLATE_SUBTITLES_DATA: {
      return {
        ...state,
        translateSubtitles: {
          translatingIntoLocale: null,
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    }

    case BiteActionTypes.TRANSLATE_SUBTITLES_ERROR: {
      return {
        ...state,
        translateSubtitles: {
          ...state.translateSubtitles,
          isLoading: false,
          error: action.payload,
        },
        alwaysTranslateToLocale: {
          ...state.alwaysTranslateToLocale,
          isInitialTranslationDone: true,
        },
      };
    }

    case BiteActionTypes.TRANSLATE_VOICEOVERS: {
      return {
        ...state,
        translatedVoiceoversLocale: action.payload.locale,
      };
    }
    case BiteActionTypes.TRANSLATE_VOICEOVERS_REQUEST: {
      return {
        ...state,
        translateVoiceoversByLocale: {
          ...state.translateVoiceoversByLocale,
          [action.payload]: {
            isLoading: true,
            pendingAudio: null,
            audio: null,
            error: null,
          },
        },
      };
    }
    case BiteActionTypes.SET_TRANSLATE_VOICEOVERS_PENDING_DATA: {
      return {
        ...state,
        translateVoiceoversByLocale: {
          ...state.translateVoiceoversByLocale,
          [action.payload.locale]: {
            isLoading: true,
            pendingAudio: action.payload.pendingAudio,
            audio: null,
            error: null,
          },
        },
      };
    }
    case BiteActionTypes.SET_TRANSLATE_VOICEOVERS_DATA: {
      if (action.payload.audio === null) {
        const translateVoiceoversByLocale = { ...state.translateVoiceoversByLocale };
        delete translateVoiceoversByLocale[action.payload.locale];
        return {
          ...state,
          translatedVoiceoversLocale: null,
          translateVoiceoversByLocale,
        };
      }

      return {
        ...state,
        translateVoiceoversByLocale: {
          ...state.translateVoiceoversByLocale,
          [action.payload.locale]: {
            isLoading: false,
            pendingAudio: null,
            audio: action.payload.audio,
            error: null,
          },
        },
      };
    }
    case BiteActionTypes.TRANSLATE_VOICEOVERS_ERROR: {
      return {
        ...state,
        translateVoiceoversByLocale: {
          ...state.translateVoiceoversByLocale,
          [action.payload.locale]: {
            isLoading: false,
            pendingAudio: null,
            audio: null,
            error: action.payload.error,
          },
        },
      };
    }
    case BiteActionTypes.SET_TRANSLATED_VOICEOVERS_LOCALE: {
      return {
        ...state,
        translatedVoiceoversLocale: action.payload,
      };
    }

    case BiteActionTypes.SET_CURRENT_LOCALE: {
      return {
        ...state,
        currentLocale: action.payload,
      };
    }

    default:
      return state;
  }
};

export default biteReducer;
