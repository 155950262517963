import React, { useState, useRef, useEffect, FC, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import Lightbox from 'react-image-lightbox';
import { ReactComponent as PlayIcon } from '../../../assets/icons/triangle.svg';
import {
  canPlayVideoSelector,
  selectedBiteSelector,
  subtitlesDisabledSelector,
  translatedVoiceoversLocaleSelector,
  translateSubtitlesErrorSelector,
  translateSubtitlesIsLoadingSelector,
  translateSubtitlesTranslatingIntoLocaleSelector,
  translateVoiceoversAudioSelector,
  translateVoiceoversErrorSelector,
  translateVoiceoversIsLoadingSelector,
} from '../../../store/bite/bite.selectors';
import { disableVideoPlaying, translateSubtitles } from '../../../store/bite/bite.actions';
import { getIsRtl } from '../../../locale/i18n';
import { ContinueButton, VideoPlayer } from '../../../components/shared';
import VideoModal from '../../../components/shared/CustomModal/VideoModal/VideoModal';
import GoToNextBiteBlueButton from '../../../screens/viewBite/GoToNextBite/GoToNextBiteBlueButton';
import useMoveToNextBiteSection from '../../../hooks/useMoveToNextBiteSection';
import gtmTrack from '../../../services/googleTagManager/track';
import { authSelector } from '../../../store/auth/auth.selectors';
import getIsWatchTillEndBanner from '../../../utils/getIsShowWatchTillEndBanner';
import useQueryParams from '../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { introContainerDataSet } from './intro.constants';
import SubtitlesTranslations from './components/SubtitlesTranslations';
import getIsMobileDevice from '../../../utils/getIsMobileDevice';
import useScreenOrientation from '../../../hooks/useScreenOrientation';
import { useLocalesWithVoiceover } from '../../../hooks/useTranslationOptions';
import TranslateVoiceoversIsLoadingOverlay from './components/TranslateVoiceoversIsLoadingOverlay';
import { organizationHasFeatureFlagSelector } from '../../../store/profile/profile.selectors';
import { Features } from '../../../utils/featureFlag/featureFlag.types';
import { ISubtitles } from '../../../types/bite';

interface IProps {
  intro: {
    subject: string;
    taskId: string;
    coverImage: string | null;
    file_type: 'image' | 'video' | 'youtube' | 'google_slides';
    media_url: string | null;
    width: number;
    height: number;
    videoStartsAt: null | number;
    videoEndsAt: null | number;
    image_url: string | null;
    subtitles_url: string | null;
    subtitles_locale: string;
    is_cc_enabled?: boolean;
    isSkipable?: boolean;
    isVideoWatchCompleted?: boolean;
    translateSubtitlesData: ISubtitles;
  };
  isModalOpen: boolean;
  onFullyWatched: () => void;
  setIsModalOpen: any;
  hasMoreSections?: boolean;
}

const Intro: FC<IProps> = ({ onFullyWatched, intro, isModalOpen, setIsModalOpen, hasMoreSections }) => {
  const {
    file_type,
    media_url,
    image_url,
    width,
    height,
    coverImage,
    videoStartsAt,
    videoEndsAt,
    subtitles_url,
    subtitles_locale,
    is_cc_enabled,
    isSkipable,
    isVideoWatchCompleted,
    translateSubtitlesData,
    taskId,
  } = intro;

  const rtl = getIsRtl();

  const isMobileDevice = getIsMobileDevice();
  const { isScreenLandscape } = useScreenOrientation();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // translate subtitles
  const subtitlesDisabled = useSelector(subtitlesDisabledSelector);
  const isTranslatingSubtitles = useSelector(translateSubtitlesIsLoadingSelector);
  const translatingError = useSelector(translateSubtitlesErrorSelector);
  const translatingIntoLocale = useSelector(translateSubtitlesTranslatingIntoLocaleSelector);

  const { selectedBite } = useSelector(selectedBiteSelector);
  const voiceoverTranslationsFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId: selectedBite?.orgid,
      feature: Features.VOICEOVER_TRANSLATIONS,
    }),
  );

  // translate voiceovers
  const translatedVoiceoversLocale = useSelector(translatedVoiceoversLocaleSelector);
  const translateVoiceoversIsLoading = useSelector(translateVoiceoversIsLoadingSelector(translatedVoiceoversLocale));
  const audio = useSelector(translateVoiceoversAudioSelector(translatedVoiceoversLocale));
  const translateVoiceoversError = useSelector(translateVoiceoversErrorSelector(translatedVoiceoversLocale));

  const withTranslationControlsDisplayAnimationRef = useRef(true);

  useEffect(() => {
    if (isModalOpen) {
      withTranslationControlsDisplayAnimationRef.current = false;
    }
  }, [isModalOpen]);

  const { queryParams } = useQueryParams();
  const playlistId = queryParams?.playlistId;

  const currentSubtitles = useMemo<{ url: string; locale: string }>(() => {
    if (subtitlesDisabled) {
      return {
        url: null,
        locale: null,
      };
    }

    return {
      url: translateSubtitlesData ? translateSubtitlesData.s3_url : subtitles_url,
      locale: translateSubtitlesData ? translateSubtitlesData.locale : subtitles_locale,
    };
  }, [subtitlesDisabled, subtitles_locale, subtitles_url, translateSubtitlesData]);

  const [isCoverLightboxOpen, setIsCoverLightboxOpen] = useState(false);

  const playVideo = () => {
    dispatch(disableVideoPlaying());
    setIsModalOpen(true);
    setIsShowSubtitlesTranslationsBanner(false);
  };

  const canPlayVideo = useSelector(canPlayVideoSelector);

  const auth = useSelector(authSelector);

  const playerRef = useRef<ReactPlayer>(null);
  const modalPlayerRef = useRef<ReactPlayer>(null);

  const isVideo = (): boolean => ['video', 'youtube'].includes(file_type);

  const handleClick = () => {
    if (!isModalOpen) {
      if (file_type === 'image') {
        setIsCoverLightboxOpen(true);
      } else if (isVideo()) {
        playVideo();
      }
    }
  };

  const { proceedToNextSection, nextSectionPath } = useMoveToNextBiteSection({
    sectionType: 'intro',
  });

  const needShowNextArrow = window.location.search.includes('playlistId') && !hasMoreSections;

  // const handleFeelingLucky = useCallback(() => {
  //   proceedToNextSection();
  // }, [proceedToNextSection]);

  useEffect(() => {
    gtmTrack('intro_section', {
      bite_id: selectedBite?.bite,
      bite_share_id: selectedBite?.id,
      content_org_id: selectedBite?.orgid,
      sharing_mode: selectedBite?.sharing_mode,
      playlist_id: playlistId,
    });
  }, [playlistId, selectedBite]);

  // const [lastActivity, setLastActivity] = useState(null);
  // const [isLastActivityVisible, setIsLastActivityVisible] = useState(null);
  // useEffect(() => {
  //   const run = async () => {
  //     while (isMountedRef.current) {
  //       try {
  //         await new Promise((resolve) => setTimeout(resolve, 2000));

  //         const biteShareId = selectedBite?.id;
  //         if (!biteShareId) {
  //           return;
  //         }

  //         const { data: lastActivityResult } = await getLastBiteActivity({
  //           biteShareId,
  //         });

  //         if (
  //           lastActivityResult.lastIntro ||
  //           lastActivityResult.lastQuestion ||
  //           lastActivityResult.totalIntro ||
  //           lastActivityResult.totalQuestion
  //         ) {
  //           setLastActivity(lastActivityResult);
  //           setIsLastActivityVisible(true);
  //         }

  //         await new Promise((resolve) => setTimeout(resolve, 5000));

  //         setIsLastActivityVisible(false);

  //         await new Promise((resolve) => setTimeout(resolve, 2000));
  //       } catch (e) {
  //         setIsLastActivityVisible(false);
  //       }
  //     }
  //   };

  //   run();
  // }, [isMountedRef, selectedBite?.id]);

  const [isShowSubtitlesTranslationsBanner, setIsShowSubtitlesTranslationsBanner] = useState(false);
  const setIsShowSubtitlesTranslationsBannerTimeoutRef = useRef(null);
  const localesWithVoiceover = useLocalesWithVoiceover();
  const handleSelectSubtitlesLang = useCallback(
    ({
      locale,
      isManually,
      withSetCurrentLocale,
    }: {
      locale: string;
      isManually?: boolean;
      withSetCurrentLocale?: boolean;
    }) => {
      clearTimeout(setIsShowSubtitlesTranslationsBannerTimeoutRef.current);

      dispatch(translateSubtitles({ locale, isManually, withSetCurrentLocale }));
    },
    [dispatch],
  );

  useEffect(() => {
    clearTimeout(setIsShowSubtitlesTranslationsBannerTimeoutRef.current);

    const orgId = selectedBite?.orgid;
    const locale = currentSubtitles?.locale;
    if (!locale || !orgId) {
      return;
    }

    if (isModalOpen && voiceoverTranslationsFeatureFlag && localesWithVoiceover.has(locale)) {
      withTranslationControlsDisplayAnimationRef.current = true;
      setIsShowSubtitlesTranslationsBanner(true);

      setIsShowSubtitlesTranslationsBannerTimeoutRef.current = setTimeout(() => {
        setIsShowSubtitlesTranslationsBanner(false);
        withTranslationControlsDisplayAnimationRef.current = true;
      }, 7000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubtitles?.locale]);

  const isShowTopBanner = getIsWatchTillEndBanner(selectedBite);
  const isShowVoiceoverTranslationsOverlay =
    !subtitlesDisabled && (translateVoiceoversIsLoading || translateVoiceoversError);

  useEffect(() => {
    if (isModalOpen && translateVoiceoversIsLoading) {
      const video = modalPlayerRef.current.getInternalPlayer() as HTMLVideoElement;
      video?.pause();
    }
  }, [isModalOpen, translateVoiceoversIsLoading]);

  return (
    <>
      <S.Wrapper
        isGoogleSlidesLandscape={isMobileDevice && !isScreenLandscape}
        isGoogleSlides={file_type === 'google_slides'}
      >
        {file_type === 'image' && (
          <S.ImageContainer onClick={handleClick} file_type={file_type}>
            <S.CoverImg src={file_type === 'image' ? image_url : coverImage} />
          </S.ImageContainer>
        )}
        {file_type === 'google_slides' && (
          <S.IframeWrapper>
            <S.IframeContainer
              isScreenLandscape={isScreenLandscape}
              showLandscape={isMobileDevice && width > height}
              src={media_url}
              allowFullScreen={false}
              isPortrait={width < height}
              slidesWidth={width}
              slidesHeight={height}
            />
          </S.IframeWrapper>
        )}
        {!isModalOpen && isVideo() && (
          <>
            <S.Container image_url={image_url} coverImage={coverImage}>
              <VideoPlayer
                onFullyWatched={null}
                closeModal={() => setIsModalOpen(false)}
                media_url={media_url}
                file_type={file_type}
                videoEndsAt={videoEndsAt}
                videoStartsAt={videoStartsAt}
                section={'intro'}
                playerRef={playerRef}
                playing={true}
                loop={true}
                muted={true}
                controls={false}
                isFullScreen={false}
                subtitles_url={subtitles_url}
                subtitles_locale={subtitles_locale}
                is_cc_enabled={is_cc_enabled}
                isSkipable={isSkipable}
                isVideoWatchCompleted={isVideoWatchCompleted}
              />
              <S.IconContainer onClick={handleClick} file_type={file_type} data-cy={introContainerDataSet.cy}>
                <S.PlayButton data-test='play' display={canPlayVideo ? 'flex' : 'none'}>
                  <S.PlayIcon id='btnPlayVideo' />
                </S.PlayButton>
              </S.IconContainer>
            </S.Container>
            {/* {!isVideoWatchCompleted ? (
              <S.LuckyButton onClick={handleFeelingLucky}>{t('screens.viewBite.skipToQuestions.text')}</S.LuckyButton>
            ) : null} */}
          </>
        )}
        {file_type === 'image' || (file_type === 'google_slides' && nextSectionPath) ? (
          <S.ContinueButton
            isScreenLandscape={isScreenLandscape && file_type === 'google_slides'}
            onClick={() => {
              proceedToNextSection();
              gtmTrack('intro_continue_button', { bites_user_id: auth?.id });
            }}
          />
        ) : (
          needShowNextArrow && <GoToNextBiteBlueButton />
        )}
      </S.Wrapper>
      {isCoverLightboxOpen && (
        <Lightbox
          mainSrc={file_type === 'image' ? image_url : coverImage}
          onCloseRequest={() => {
            setIsCoverLightboxOpen(false);
          }}
        />
      )}

      {!isModalOpen && isVideo() && getIsWatchTillEndBanner(selectedBite) && (
        <S.TeaserBanner onClick={handleClick}>
          <S.TeaserBannerInner
          //isFlipped={!!isLastActivityVisible}
          >
            <S.WatchTillEndBanner rtl={rtl}>
              {t('screens.viewBite.watchTillEndBanner.section.text1')}
              <S.WatchTillEndBannerBoldText rtl={rtl}>
                {t('screens.viewBite.watchTillEndBanner.section.text2')}
              </S.WatchTillEndBannerBoldText>
            </S.WatchTillEndBanner>
            {/* <S.ActivityBanner>
              {lastActivity?.lastIntro ? (
                <div>
                  <S.ActivityNumber>{lastActivity.lastIntro}</S.ActivityNumber> watching video now
                </div>
              ) : lastActivity?.totalIntro ? (
                <div>
                  <S.ActivityNumber>{lastActivity.totalIntro}</S.ActivityNumber> watched video
                </div>
              ) : null}
              {lastActivity?.lastQuestion ? (
                <div>
                  <S.ActivityNumber>{lastActivity.lastQuestion}</S.ActivityNumber> answering question now
                </div>
              ) : lastActivity?.totalQuestion ? (
                <div>
                  <S.ActivityNumber>{lastActivity.totalQuestion}</S.ActivityNumber> answered question
                </div>
              ) : null}
            </S.ActivityBanner> */}
          </S.TeaserBannerInner>
        </S.TeaserBanner>
      )}
      {isModalOpen && (
        <VideoModal
          onFullyWatched={onFullyWatched}
          closeModal={() => setIsModalOpen(false)}
          media_url={media_url}
          taskId={taskId}
          currentSubtitles={currentSubtitles}
          subtitles={selectedBite?.subtitles}
          onSelectSubtitlesLang={handleSelectSubtitlesLang}
          file_type={file_type}
          videoEndsAt={videoEndsAt}
          videoStartsAt={videoStartsAt}
          is_cc_enabled={is_cc_enabled}
          isSkipable={isSkipable}
          isVideoWatchCompleted={isVideoWatchCompleted}
          isShowTopBanner={isShowTopBanner}
          isTranslatingSubtitles={isTranslatingSubtitles}
          isShowVoiceoverTranslationsOverlay={isShowVoiceoverTranslationsOverlay}
          subtitlesDisabled={subtitlesDisabled}
          section={'intro'}
          audio={!subtitlesDisabled ? audio : null}
          playerRef={modalPlayerRef}
        />
      )}

      <SubtitlesTranslations
        mode={isModalOpen ? 'CONTROLS' : null}
        withLocalesTabs={!isModalOpen}
        withAlwaysTranslate={!isModalOpen}
        withDisplayAnimation={withTranslationControlsDisplayAnimationRef.current}
        currentLocale={currentSubtitles.locale}
        subtitles={selectedBite?.subtitles}
        onSelect={handleSelectSubtitlesLang}
        isVisible={isShowSubtitlesTranslationsBanner || !isModalOpen}
        isLoading={isTranslatingSubtitles}
        isError={!isModalOpen && !!translatingError}
        translatingIntoLocale={translatingIntoLocale}
      />

      {isShowVoiceoverTranslationsOverlay && <TranslateVoiceoversIsLoadingOverlay withPlayVideoButton={isModalOpen} />}
    </>
  );
};

const CONTINUE_BUTTON_HEIGHT = 40;

const S: any = {
  // LuckyButton: styled.div`
  //   position: relative;
  //   display: flex;
  //   justify-content: center;
  //   padding: 8px;
  //   height: 16px;
  //   cursor: pointer;
  //   text-decoration: underline;
  //   color: #ff0083;
  // `,
};

S.Wrapper = styled.div<{ isGoogleSlidesLandscape: boolean; isGoogleSlides?: boolean }>`
  padding: ${({ isGoogleSlides }) => (isGoogleSlides ? '8px' : '30px')};
  height: ${({ isGoogleSlidesLandscape, isGoogleSlides }) =>
    !isGoogleSlides || isGoogleSlidesLandscape ? '100%' : 'auto'};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex: 1;
`;
S.ImageContainer = styled.div<{ file_type: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  ${({ file_type }) => file_type === 'image' && 'margin-bottom: 10px'};
`;
S.IframeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
`;
S.IframeContainer = styled.iframe<{
  isPortrait: boolean;
  isScreenLandscape: boolean;
  showLandscape: boolean;
  slidesWidth: number;
  slidesHeight: number;
}>`
  height: ${({ showLandscape, isScreenLandscape, isPortrait, slidesWidth, slidesHeight }) =>
    showLandscape
      ? isScreenLandscape
        ? 'calc(100dvh - 68px)'
        : `calc((100dvh - 200px) * ${slidesHeight / slidesWidth})`
      : isPortrait
      ? '100%'
      : 'auto'};
  width: ${({ showLandscape, isScreenLandscape, isPortrait, slidesWidth, slidesHeight }) =>
    showLandscape
      ? isScreenLandscape
        ? `calc((100dvh - 68px) * ${slidesWidth / slidesHeight})`
        : 'calc(100dvh - 200px)'
      : isPortrait
      ? 'auto'
      : '100%'};
  max-width: ${({ showLandscape, isScreenLandscape }) => (showLandscape && !isScreenLandscape ? 'auto' : '100%')};
  aspect-ratio: ${({ slidesWidth, slidesHeight }) => `${slidesWidth / slidesHeight}`};
  ${({ showLandscape, isScreenLandscape }) =>
    showLandscape && !isScreenLandscape ? 'transform: rotate(90deg)' : null};
  border: 0;
  border-radius: 32px;
`;
S.Container = styled.div<{
  file_type: string;
  image_url: string;
  coverImage: string;
}>`
  position: relative;
  display: block;
  width: 100%;
  height: calc(((100vw - 60px) * 5) / 4);
  cursor: pointer;
  background-image: url(${({ file_type, image_url, coverImage }) => (file_type === 'image' ? image_url : coverImage)});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-transform: translateZ(0);
  box-shadow: 0 19px 51px 0 rgba(0, 0, 0, 0.16), 0 14px 19px 0 rgba(0, 0, 0, 0.07);
  border-radius: 32px;
  overflow: hidden;
  max-width: 440px;
  max-height: 550px ${({ file_type }) => file_type === 'image' && 'margin-bottom: 10px'};
`;

S.IconContainer = styled.div<{ file_type: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 15px;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
`;

S.TeaserBanner = styled.div`
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 10px;
  width: 178px;
  height: 178px;
  color: white;
  cursor: pointer;
  perspective: 1000px;
`;
S.TeaserBannerInner = styled.div<{ isFlipped: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  ${({ isFlipped }) => (isFlipped ? 'transform: rotateY(180deg);' : '')}
`;
S.WatchTillEndBanner = styled.div<{ rtl: boolean }>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 34px;
  border-radius: 50%;
  background: ${({ theme }) =>
    `linear-gradient(149.47deg, ${theme.colors.lightPurple1} 21.66%, ${theme.colors.orange1} 73.79%)`};
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  ${({ rtl }) => (rtl ? 'direction: rtl;' : '')}
`;
S.WatchTillEndBannerBoldText = styled.div<{ rtl: boolean }>`
  margin-top: 1px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  ${({ rtl }) => (rtl ? 'direction: rtl;' : '')}
`;
S.ActivityBanner = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50%;
  background: ${({ theme }) =>
    `linear-gradient(70deg, ${theme.colors.lightPurple1} 21.66%, ${theme.colors.orange1} 73.79%)`};

  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;
S.ActivityNumber = styled.div`
  font-size: 30px;
`;

S.CoverImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  max-width: 100%;
  max-height: calc(100% - ${CONTINUE_BUTTON_HEIGHT}px);
`;

S.PlayButton = styled.div<{ display: string }>`
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ display }) => `
		display: ${display}
	`};

  ${({ theme }) => css`
    border: 2px solid ${theme.colors.white};
  `};
`;

S.PlayIcon = styled(PlayIcon)`
  width: 40px;
  transform: translateX(10%);
  transform-origin: left;
  ${({ theme }) => css`
    fill: ${theme.colors.white};
  `};
`;

S.Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 23.5px;
  margin-bottom: 16.5px;
  z-index: 1;
  ${({ theme }) => css`
    background-color: ${theme.colors.lightGray7};
  `};
`;

S.Subject = styled.p`
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  direction: ${() => (getIsRtl() ? 'rtl' : 'ltr')};
  writing-mode: horizontal-tb;
  z-index: 1;
  ${({ theme }) => css`
    color: ${theme.colors.white};
  `};
`;
S.ContinueButton = styled(ContinueButton)<{ isScreenLandscape: boolean }>`
  margin-top: 12px;
  height: ${CONTINUE_BUTTON_HEIGHT}px;
`;

export default Intro;
