import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchHistory,
  fetchHistoryFailure,
  fetchKnowledge,
  fetchKnowledgeFailure,
  fetchKnowledgeRequest,
  fetchTagSearchData,
  fetchTagSearchDataFailure,
  resetTagSearchData,
  setHistory,
  setKnowledge,
  setKnowledgeAnswer,
  setTagSearchData,
} from './knowledge.slice';
import { orgSelector } from '../organization/organization.selectors';
import { aiKnowledgeRequest, aiKnowledgeHistoryRequest } from '../api/calls/ai.calls';
import * as calls from '../api/calls/bite.calls';
import {
  knowledgeHistorySelector,
  knowledgeRequestPromptSelector,
  knowledgeTagsSearchDataNextSelector,
  requestIdSelector,
} from './knowledge.selectors';
import { log, logError } from '../tracking/tracking.slice';
import { cloneDeep } from 'lodash';
import queryString from 'query-string';
import { authSelector } from '../auth/auth.selectors';
import withRetry from '../../utils/withRetry';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchKnowledgeSaga({ payload }: PayloadAction<string>) {
  const prompt = payload.trim();

  if (!prompt.length) {
    return;
  }

  yield put(fetchKnowledgeRequest(payload));
  // yield fork(fetchTagSearchDataSaga);

  const { id: orgId } = yield select(orgSelector);
  yield put(log({ event: 'knowledge.saga fetchKnowledgeSaga: start' }));
  try {
    const {
      data: { result, relatedContent, searchRelatedContent },
    } = yield withRetry(() => aiKnowledgeRequest(orgId, prompt), {
      errorContext: {
        action: 'fetchKnowledgeSaga',
        data: {
          orgId,
          prompt,
        },
      },
    });

    yield put(setKnowledgeAnswer({ answer: result }));

    const relatedBiteShareIds = new Set(relatedContent?.map((relatedBiteShare) => relatedBiteShare.biteShareId));
    const searchRelatedContentBites = !result
      ? searchRelatedContent.filter(
          (searchRelatedBiteShare) => !relatedBiteShareIds.has(searchRelatedBiteShare.biteShareId),
        )
      : [];

    const [answerRelatedBites, searchRelatedBites] = yield all([
      all(
        relatedContent.map(async ({ biteShareId }) => {
          try {
            return await withRetry(() => calls.getById(biteShareId), {
              errorContext: {
                action: 'fetchKnowledgeSaga',
                data: { biteShareId },
              },
            });
          } catch (error) {
            return null;
          }
        }),
      ),
      all(
        searchRelatedContentBites.map(async ({ biteShareId }) => {
          try {
            return await withRetry(() => calls.getById(biteShareId), {
              errorContext: {
                action: 'fetchKnowledgeSaga',
                data: { biteShareId },
              },
            });
          } catch (error) {
            return null;
          }
        }),
      ),
    ]);

    yield put(
      log({
        event: 'knowledge.saga fetchKnowledgeSaga: success',
        data: { result, relatedContent, searchRelatedContent },
      }),
    );

    yield put(
      setKnowledge({
        relatedContent: {
          answerRelated: answerRelatedBites.filter(Boolean).map(({ data }) => data),
          searchRelated: searchRelatedBites.filter(Boolean).map(({ data }) => data),
        },
      }),
    );
  } catch (error) {
    yield put(fetchKnowledgeFailure());
    yield put(
      logError({
        event: 'knowledge.saga fetchKnowledgeSaga: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
  }
}

function* fetchTagSearchDataSaga() {
  const prompt = yield select(knowledgeRequestPromptSelector);
  const requestId = yield select(requestIdSelector);

  // need to check in case this was a next page action
  if (!requestId) {
    yield put(resetTagSearchData());
    return;
  }

  try {
    const { id: orgId } = yield select(orgSelector);
    const searchNext = yield select(knowledgeTagsSearchDataNextSelector);
    const nextPage = searchNext ? queryString.parse(searchNext).page : 1;

    const searchParams = {
      feed: true,
      page_size: 20,
      pagination: true,
      organization: orgId,
      page: nextPage,
      search: prompt,
    };

    const queryParams = '?' + queryString.stringify(searchParams);

    const {
      data: { results, next, count },
    } = yield withRetry(() => calls.query(queryParams), {
      errorContext: {
        action: 'fetchTagSearchDataSaga',
      },
    });

    const currentRequestId = yield select(requestIdSelector);
    if (requestId !== currentRequestId) {
      return;
    }

    yield put(setTagSearchData({ data: results, next, count }));
  } catch (error) {
    yield put(fetchTagSearchDataFailure());
    yield put(
      logError({
        event: 'knowledge.saga fetchTagSearchDataSaga: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
  }
}

function* fetchKnowledgeHistorySaga() {
  try {
    const { id: orgId } = yield select(orgSelector);
    const { id: userId } = yield select(authSelector);
    const {
      data: { results },
    } = yield withRetry(
      () =>
        aiKnowledgeHistoryRequest({
          filters: {
            orgId,
            userId,
          },
          fields: {
            historyItem: ['id', 'prompt'],
          },
          pageSize: 20,
          page: 0,
        }),
      {
        errorContext: {
          action: 'fetchKnowledgeHistorySaga',
        },
      },
    );

    const currentHistory = yield select(knowledgeHistorySelector);
    const uniquePrompts = new Set();
    const result = [];
    [...(currentHistory || []), ...results].forEach((item) => {
      if (!uniquePrompts.has(item.historyItem.prompt)) {
        uniquePrompts.add(item.historyItem.prompt);
        result.push({
          id: item.historyItem.id,
          prompt: item.historyItem.prompt,
        });
      }
    });

    yield put(setHistory({ data: result }));
  } catch (error) {
    yield put(fetchHistoryFailure());
    yield put(
      logError({
        event: 'knowledge.saga fetchKnowledgeHistorySaga: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
  }
}

export default function* knowledgeSaga() {
  yield all([
    takeLatest(fetchKnowledge, fetchKnowledgeSaga),
    takeLatest(fetchHistory, fetchKnowledgeHistorySaga),
    takeLatest(fetchTagSearchData, fetchTagSearchDataSaga),
  ]);
}
