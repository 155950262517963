import React, { useMemo, useEffect, useCallback, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { SideControls } from './components/SideControls';
import {
  currentBiteLocaleSelector,
  selectedBiteSelector,
  translateSubtitlesDataSelector,
  translateSubtitlesIsLoadingSelector,
  translateSubtitlesTranslatingIntoLocaleSelector,
} from '../../store/bite/bite.selectors';
import {
  fetchSelectedBiteRequest,
  setTranslateSubtitlesData,
  setCurrentLocale,
  translateSubtitles,
} from '../../store/bite/bite.actions';
import { IDynamicBiteSectionDetails } from '../../types/viewBite';
import { Intro, BiteQuestion, Summary, SummaryMediaViewer, Discussion, Content } from '../../screens/viewBite';
import { ReactComponent as IntroSVG } from '../../assets/icons/intro.svg';
import { ReactComponent as QuestionSVG } from '../../assets/icons/question.svg';
import { ReactComponent as SummarySVG } from '../../assets/icons/summary.svg';
import { ReactComponent as DiscussionSVG } from '../../assets/icons/discussion.svg';
import { biteShareUserSelector } from '../../store/biteShareUser/biteShareUser.selectors';
import { getIsRtl } from '../../locale/i18n';
import { fullyWatchedBiteIntroVideo } from '../../store/biteShareUser/biteShareUser.actions';
import useRedirectToBiteSection from '../../hooks/useRedirectToBiteSection';
import { getEnhancedMedia } from '../../utils/bite';
import { log, logError } from '../../store/tracking/tracking.slice';
import { translateTexts } from '../../store/api/calls/common.calls';
import { useIsMounted } from '../../hooks/useIsMounted';
import withRetry from '../../utils/withRetry';
import useQueryParams from '../../hooks/useQueryParams';

const StyledIntroSVG = styled(IntroSVG)`
  padding: 8px;
`;
const StyledDiscussionSVG = styled(DiscussionSVG)`
  padding: 10px 8px 8px;
`;
const StyledSummarySVG = styled(SummarySVG)`
  padding: 7px;
  transform: translateY(-2px);
`;
const StyledQuestionSVG = styled(QuestionSVG)`
  padding: 2px;
`;

export default ({ isModalOpen, setIsModalOpen, processId, isPlaylist }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMountedRef = useIsMounted();
  const questionLocaleRef = useRef(null);
  const questionLocaleTranslatingRef = useRef(null);
  const summaryLocaleRef = useRef(null);
  const summaryLocaleTranslatingRef = useRef(null);

  const path = history.location.pathname;
  const currentSection = path.slice(path.lastIndexOf('/') + 1);

  const match = useRouteMatch() as any;
  const biteId = match.params.id;

  const { queryParams } = useQueryParams();
  const hideHeader = queryParams?.hideHeader;

  const { selectedBite, isLoading } = useSelector(selectedBiteSelector);
  const biteShareUser = useSelector(biteShareUserSelector);

  const currentLocale = useSelector(currentBiteLocaleSelector);
  const translateSubtitlesData = useSelector(translateSubtitlesDataSelector);
  const isTranslatingSubtitles = useSelector(translateSubtitlesIsLoadingSelector);
  const translateSubtitlesTranslatingIntoLocale = useSelector(translateSubtitlesTranslatingIntoLocaleSelector);

  const sections = useRef([]);

  const redirectToBiteSection = useRedirectToBiteSection(selectedBite, biteShareUser);

  const getNextSection = useCallback((name, type) => {
    const currentPathIdx = sections.current.findIndex((currSectionType) => currSectionType === name);
    if (type === 'next') {
      return sections.current.length - 1 >= currentPathIdx + 1 ? sections.current[currentPathIdx + 1] : null;
    } else {
      return currentPathIdx - 1 >= 0 ? sections.current[currentPathIdx - 1] : null;
    }
  }, []);

  const handleMove = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, direction, fromSection) => {
      e.preventDefault();

      if (hideHeader) {
        return;
      }

      if (getIsRtl()) {
        direction = direction === 'next' ? 'back' : 'next';
      }

      const requestedSectionName = getNextSection(fromSection, direction);
      redirectToBiteSection(requestedSectionName);
    },
    [getNextSection, hideHeader, redirectToBiteSection],
  );

  const handleFullyWatched = useCallback(
    () => dispatch(fullyWatchedBiteIntroVideo(biteShareUser.id)),
    [dispatch, biteShareUser?.id],
  );

  const introSection = useMemo(
    () => selectedBite?.bite_sections.find((section) => section.type === 'intro'),
    [selectedBite?.bite_sections],
  );
  const introMedia = useMemo(
    () => getEnhancedMedia(selectedBite) || introSection?.media,
    [introSection?.media, selectedBite],
  );
  const questionSection = useMemo(
    () => selectedBite?.bite_sections.find((section) => section.type === 'question'),
    [selectedBite],
  );
  const summarySection = useMemo(
    () => selectedBite?.bite_sections.find((section) => section.type === 'summary'),
    [selectedBite],
  );

  const hasQuestion = questionSection?.questions?.length > 0;
  const hasSummary = summarySection?.summary_cards?.length > 0;

  const subtitles = useMemo(() => selectedBite?.subtitles?.find((x) => x.enabled), [selectedBite?.subtitles]);
  const hasMoreSections = useMemo(
    () => selectedBite?.discussion_enabled || hasQuestion || hasSummary,
    [hasQuestion, hasSummary, selectedBite?.discussion_enabled],
  );

  const intro = useMemo(
    () => ({
      file_type: introMedia?.file_type,
      media_url: introMedia?.media_url,
      image_url: introMedia?.image_url,
      width: introMedia?.width,
      height: introMedia?.height,
      coverImage: selectedBite?.cover_url,
      subject: selectedBite?.subject,
      videoStartsAt: introMedia?.video_start_at,
      videoEndsAt: introMedia?.video_ends_at,
      subtitles_url: subtitles?.s3_url || null,
      subtitles_locale: subtitles?.locale,
      is_cc_enabled: introMedia?.is_cc_enabled,
      isSkipable: selectedBite?.skip_able,
      isVideoWatchCompleted: biteShareUser?.video_progress === 100,
      translateSubtitlesData,
      taskId: introMedia?.task_id,
    }),
    [introMedia, translateSubtitlesData, selectedBite, subtitles, biteShareUser?.video_progress],
  );

  const question = questionSection?.questions[0];
  const hasSummaryOrDiscussion = selectedBite?.discussion_enabled || hasSummary;

  const choices = useMemo(() => {
    if (!question?.choices) {
      return [];
    }
    return [...question.choices].sort((a, b) => a.order - b.order);
  }, [question?.choices]);

  const summaryMedia = useMemo(
    () =>
      summarySection?.media && {
        file_type: summarySection.media.file_type,
        media_url: summarySection.media.media_url,
        image_url: summarySection.media.image_url,
        coverImage: selectedBite.cover_url,
        subject: '',
        videoStartsAt: summarySection.media.video_start_at,
        videoEndsAt: summarySection.media.video_ends_at,
      },
    [summarySection, selectedBite],
  );

  const comments = selectedBite?.comments;

  const contentSec = useMemo(
    () => selectedBite?.bite_sections.find((sec) => sec?.type === 'content'),
    [selectedBite?.bite_sections],
  );

  const html = contentSec?.content?.content;

  const [isLoadingTranslations, setIsLoadingTranslations] = useState(false);
  const [questionTranslation, setQuestionTranslation] = useState(null);
  const [choicesTranslations, setChoicesTranslations] = useState([]);
  const [summaryTranslations, setSummaryTranslations] = useState([]);

  const selectLocaleQuestion = useCallback(
    async (locale) => {
      const startTs = Date.now();
      questionLocaleTranslatingRef.current = locale;

      dispatch(
        log({
          event: 'useLogic.selectLocaleQuestion: start',
          data: {
            locale,
          },
        }),
      );

      if (!locale) {
        setQuestionTranslation(null);
        setChoicesTranslations([]);
        dispatch(setCurrentLocale(null));
        return;
      }

      setIsLoadingTranslations(true);

      try {
        const optionTexts = question.type !== 'open ended' ? choices.map(({ text }) => text) : [];

        const {
          data: { texts },
        } = await withRetry(
          () =>
            translateTexts({
              texts: [question.text, ...optionTexts],
              locale,
            }),
          {
            errorContext: {
              action: 'useLogic.selectLocaleQuestion',
            },
          },
        );

        if (!isMountedRef.current || questionLocaleTranslatingRef.current !== locale) {
          return;
        }

        const newQuestionTranslation = texts.shift();
        const newChoicesTranslations = texts;

        setQuestionTranslation(newQuestionTranslation);
        setChoicesTranslations(newChoicesTranslations);
        dispatch(setCurrentLocale(locale));
        questionLocaleRef.current = locale;
        questionLocaleTranslatingRef.current = null;

        setIsLoadingTranslations(false);

        dispatch(
          log({
            event: 'useLogic.selectLocaleQuestion: done',
            data: {
              locale,
              duration: Date.now() - startTs,
            },
          }),
        );
      } catch (error) {
        dispatch(
          logError({
            event: 'useLogic.selectLocaleQuestion: error',
            data: {
              error,
              locale,
            },
          }),
        );

        if (!isMountedRef.current || questionLocaleTranslatingRef.current !== locale) {
          return;
        }

        questionLocaleTranslatingRef.current = null;
        setIsLoadingTranslations(false);
      }
    },
    [choices, dispatch, isMountedRef, question?.text, question?.type],
  );

  const selectLocaleSummary = useCallback(
    async (locale) => {
      const startTs = Date.now();
      summaryLocaleTranslatingRef.current = locale;

      dispatch(
        log({
          event: 'useLogic.selectLocaleSummary: start',
          data: {
            locale,
          },
        }),
      );

      if (!locale) {
        setSummaryTranslations([]);
        dispatch(setCurrentLocale(null));
        return;
      }

      setIsLoadingTranslations(true);

      const cardsTexts = summarySection?.summary_cards?.map(({ text }) => text);
      try {
        const {
          data: { texts },
        } = await withRetry(
          () =>
            translateTexts({
              texts: cardsTexts,
              locale,
            }),
          {
            errorContext: {
              action: 'useLogic.selectLocaleSummary',
            },
          },
        );

        if (!isMountedRef.current || summaryLocaleTranslatingRef.current !== locale) {
          return;
        }

        setSummaryTranslations(texts);
        dispatch(setCurrentLocale(locale));
        summaryLocaleRef.current = locale;
        summaryLocaleTranslatingRef.current = null;

        setIsLoadingTranslations(false);

        dispatch(
          log({
            event: 'useLogic.selectLocaleSummary: done',
            data: {
              locale,
              duration: Date.now() - startTs,
            },
          }),
        );
      } catch (error) {
        dispatch(
          logError({
            event: 'useLogic.selectLocaleSummary: error',
            data: {
              error,
              locale,
            },
          }),
        );

        if (!isMountedRef.current || summaryLocaleTranslatingRef.current !== locale) {
          return;
        }

        summaryLocaleTranslatingRef.current = null;
        setIsLoadingTranslations(false);
      }
    },
    [dispatch, isMountedRef, summarySection?.summary_cards],
  );

  const renderIntroSection = useCallback(() => {
    return (
      <SideControls onBack={(e) => handleMove(e, 'back', 'intro')} onNext={(e) => handleMove(e, 'next', 'intro')}>
        <Intro
          onFullyWatched={handleFullyWatched}
          intro={intro}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          hasMoreSections={hasMoreSections}
        />
      </SideControls>
    );
  }, [handleFullyWatched, handleMove, hasMoreSections, intro, isModalOpen, setIsModalOpen]);

  const renderQuestionSection = useCallback(() => {
    return (
      <SideControls onBack={(e) => handleMove(e, 'back', 'question')} onNext={(e) => handleMove(e, 'next', 'question')}>
        <BiteQuestion
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          question={question}
          choices={choices}
          media={questionSection?.media}
          coverUrl={selectedBite?.cover_url}
          hasSummaryOrDiscussion={hasSummaryOrDiscussion}
          questionTranslation={questionTranslation}
          choicesTranslations={choicesTranslations}
        />
      </SideControls>
    );
  }, [
    choices,
    choicesTranslations,
    handleMove,
    hasSummaryOrDiscussion,
    isModalOpen,
    question,
    questionSection?.media,
    questionTranslation,
    selectedBite?.cover_url,
    setIsModalOpen,
  ]);

  const renderSummarySection = useCallback(() => {
    return (
      <SideControls onBack={(e) => handleMove(e, 'back', 'summary')} onNext={(e) => handleMove(e, 'next', 'summary')}>
        {summarySection?.summary_cards?.length && !summaryMedia ? (
          <Summary
            cards={summarySection?.summary_cards}
            summaryTranslations={summaryTranslations}
            hasDiscussion={selectedBite?.discussion_enabled}
          />
        ) : (
          <SummaryMediaViewer
            onFullyWatched={handleFullyWatched}
            summary={summaryMedia}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </SideControls>
    );
  }, [
    handleFullyWatched,
    handleMove,
    isModalOpen,
    selectedBite?.discussion_enabled,
    setIsModalOpen,
    summaryMedia,
    summarySection?.summary_cards,
    summaryTranslations,
  ]);

  const renderDiscussionSection = useCallback(() => {
    return (
      <SideControls
        onBack={(e) => handleMove(e, 'back', 'discussion')}
        onNext={(e) => handleMove(e, 'next', 'discussion')}
      >
        <Discussion comments={comments} />
      </SideControls>
    );
  }, [comments, handleMove]);

  const renderContentSection = useCallback(() => {
    return (
      <SideControls onBack={(e) => handleMove(e, 'back', 'content')} onNext={(e) => handleMove(e, 'next', 'content')}>
        <Content html={html} />
      </SideControls>
    );
  }, [handleMove, html]);

  const dynamicBiteSectionDetails = useMemo(() => {
    const result: IDynamicBiteSectionDetails[] = [];

    // clear the sections ref array
    sections.current = [];

    if (isLoading || !selectedBite) {
      return result;
    }

    if (introMedia) {
      const introSectionDetails = {
        path: '/intro',
        route: {
          component: renderIntroSection,
        },
        link: {
          svgIcon: StyledIntroSVG,
        },
      };
      sections.current.push('intro');
      result.push(introSectionDetails);
    }

    if (question) {
      const questionSec = {
        path: '/question',
        route: {
          component: renderQuestionSection,
        },
        link: {
          svgIcon: StyledQuestionSVG,
        },
      };
      sections.current.push('question');
      result.push(questionSec);
    }

    if (summarySection?.summary_cards?.length || summaryMedia) {
      const summarySec = {
        path: '/summary',
        route: {
          component: renderSummarySection,
        },
        link: {
          svgIcon: StyledSummarySVG,
        },
      };
      sections.current.push('summary');
      result.push(summarySec);
    }

    if (selectedBite.discussion_enabled) {
      const discussionSection = {
        path: '/discussion',
        route: {
          component: renderDiscussionSection,
        },
        link: {
          svgIcon: StyledDiscussionSVG,
        },
      };

      sections.current.push('discussion');
      result.push(discussionSection);
    }

    if (!selectedBite.discussion_enabled && contentSec?.content?.type === 'html') {
      const contentSection = {
        path: '/content',
        route: {
          component: renderContentSection,
        },
        link: {
          svgIcon: StyledDiscussionSVG,
        },
      };
      sections.current.push('content');
      result.push(contentSection);
    }

    return result;
  }, [
    contentSec?.content?.type,
    introMedia,
    isLoading,
    question,
    renderContentSection,
    renderDiscussionSection,
    renderIntroSection,
    renderQuestionSection,
    renderSummarySection,
    selectedBite,
    summaryMedia,
    summarySection?.summary_cards?.length,
  ]);

  useEffect(() => {
    if (Number(selectedBite?.id) === Number(biteId)) {
      return;
    }

    dispatch(
      log({
        event: 'ViewBite: fetchSelectedBiteRequest',
        processId,
        data: {
          biteId,
        },
      }),
    );

    dispatch(fetchSelectedBiteRequest({ biteId, processId, isPlaylist }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biteId]);

  useEffect(() => {
    if (!currentLocale) {
      if (currentSection === 'intro') {
        dispatch(setTranslateSubtitlesData(null));
      }

      if (currentSection === 'question') {
        setQuestionTranslation(null);
        setChoicesTranslations([]);
      }

      if (currentSection === 'summary') {
        setSummaryTranslations([]);
      }

      return;
    }

    const subtitlesLocale = translateSubtitlesData ? translateSubtitlesData.locale : subtitles?.locale;
    if (
      currentSection === 'intro' &&
      subtitles &&
      subtitlesLocale !== currentLocale &&
      (!isTranslatingSubtitles || translateSubtitlesTranslatingIntoLocale !== currentLocale)
    ) {
      dispatch(translateSubtitles({ locale: currentLocale }));
    }

    if (
      currentSection === 'question' &&
      questionLocaleRef.current !== currentLocale &&
      (!questionLocaleTranslatingRef.current || questionLocaleTranslatingRef.current !== currentLocale)
    ) {
      selectLocaleQuestion(currentLocale);
    }

    if (
      currentSection === 'summary' &&
      summaryLocaleRef.current !== currentLocale &&
      (!summaryLocaleTranslatingRef.current || summaryLocaleTranslatingRef.current !== currentLocale)
    ) {
      selectLocaleSummary(currentLocale);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  return {
    intro,
    isLoading,
    selectedBite,
    dynamicBiteSectionDetails,
    isLoadingTranslations,
    currentSection,
    selectLocale:
      currentSection === 'question' ? selectLocaleQuestion : currentSection === 'summary' ? selectLocaleSummary : null,
  };
};
