import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import { StyledTimeAgo, StyledImageTiles } from '../shared';
import { ReactComponent as QMarkIcon } from '../../assets/icons/q-mark.svg';
import { ReactComponent as ShareSVG } from '../../assets/icons/share_icon.svg';
import { WEB_URL } from '../../constants/urls';
import useDataFormatter from '../../hooks/useDateFormatter';
import { getIsRtl } from '../../locale/i18n';
import gtmTrack from '../../services/googleTagManager/track';
import CoverImage from '../../screens/ViewPlaylist/components/CoverImage';

//TODO: convert to Partial of IPlaylist
interface IProps {
  playlist: {
    id: number;
    subject: string;
    created_at: string;
    is_quiz: boolean;
    linked_cover_url: string;
    bite_shares: any;
  };
  coverImages: string[];
  onClick?: (any: any) => void;
}

const PlaylistPreview: FC<IProps> = ({ playlist, coverImages = [], onClick }) => {
  const { subject, created_at, is_quiz, id, linked_cover_url, bite_shares } = playlist;
  const { t } = useTranslation();
  const { formatToRelativeDate } = useDataFormatter();

  const onCopyToClipboard = () => {
    toast(t('screens.home.shareToast'));
  };

  const intro = bite_shares?.find((bite) => bite.playlist_section === 'intro');
  const quizCoverUrl = linked_cover_url || intro?.cover_url;

  const handleClick = (e) => {
    e.stopPropagation();
    gtmTrack('share', { share_type: 'playlist', playlist_id: id });
  };
  return (
    <S.Container
      id='playlistContianer'
      onClick={() => onClick?.(playlist)}
      data-cy={is_quiz ? 'quizPreview' : 'playlistPreview'}
      data-cy-id={id}
    >
      <S.Main>
        <StyledImageTiles.Container id='playlistImageTilesContainer' isQuiz={is_quiz}>
          {is_quiz ? (
            <>
              <S.QuizIndicator>
                <S.QuizIndicatorIcon />
              </S.QuizIndicator>
              <StyledImageTiles.ImageTile src={quizCoverUrl} />
            </>
          ) : playlist.linked_cover_url ? (
            <CoverImage url={playlist.linked_cover_url} />
          ) : (
            coverImages.map((coverImage, idx) => <StyledImageTiles.ImageTile key={idx} src={coverImage} />)
          )}
        </StyledImageTiles.Container>

        <S.DetailsContainer id='playlistDetailsContianer'>
          <S.TimeAgoEditControllerContainer id='playlistDateText'>
            <StyledTimeAgo>{formatToRelativeDate(created_at)}</StyledTimeAgo>
          </S.TimeAgoEditControllerContainer>
          <S.Subject id='playlistTitleText' data-cy='playlistPreviewName'>
            {subject}
          </S.Subject>
        </S.DetailsContainer>
        <CopyToClipboard
          text={!is_quiz ? `${WEB_URL}/playlists/${playlist.id}` : `${WEB_URL}/quiz/${playlist.id}`}
          onCopy={onCopyToClipboard}
        >
          <S.ShareContainer onClick={handleClick}>
            <S.ShareIcon />
          </S.ShareContainer>
        </CopyToClipboard>
      </S.Main>
    </S.Container>
  );
};

const S: any = {};

S.Container = styled.div`
  border-radius: 15px;
  cursor: pointer;
  ${({ theme: { colors } }) => css`
    background-color: ${colors.lightGray22};
  `}
`;

S.Main = styled.div`
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  ${() =>
    getIsRtl() &&
    `
			flex-direction: row-reverse;
		`};
`;

S.ShareContainer = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-end;
  background-color: ${({ theme }) => theme.colors.lightGray3};
`;

S.ShareIcon = styled(ShareSVG)`
  width: 16px;
  height: 16px;
  margin: 7px;
  fill: ${({ theme }) => theme.colors.primaryBlue};
`;

S.QuizIndicator = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 43px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 9px 9px 3px 7px;
`;

S.QuizIndicatorIcon = styled(QMarkIcon)`
  width: 27px;
  height: 27px;
`;

S.DetailsContainer = styled.div`
  flex: 1;
  padding: 3px 20px 0;
  display: flex;
  flex-direction: column;
  ${() =>
    getIsRtl() &&
    `
			direction: rtl;
			text-align: right;
			padding: 0 20px 3px;
		`};
`;

S.TimeAgoEditControllerContainer = styled.div`
  margin-bottom: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

S.Subject = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.lightGray6};
`;

export default PlaylistPreview;
