import { createSelector } from 'reselect';

import { RootState } from '..';

const biteCommonSelector = (state) => state.bite;

export const bitesSelector = createSelector(biteCommonSelector, (item) => item.bites || []);

export const bitesLoaderSelector = (state: RootState) => state.bite.bites.isLoading;
export const selectedBiteSelector = (state: RootState) => state.bite.selectedBite;
export const selectedBiteSubtitlesSelector = (state: RootState) =>
  selectedBiteSelector(state).selectedBite?.subtitles || null;
export const commentSuggestionSelector = (state: RootState) => state.bite.commentSuggestion;

export const selectAddCommentSelector = (state: RootState) => state.bite.addComment;

export const canPlayVideoSelector = (state: RootState) => state.bite.selectedBite.canPlay;

export const latestChangedCommentIndexSelector = (state: RootState) =>
  state.bite.selectedBite.latestChangedCommentIndex;

export const biteSectionTypesSelector = (state: RootState) => {
  const biteSections = state.bite.selectedBite.selectedBite.bite_sections;

  const biteSectionTypes = [];

  const introSection = biteSections.find((section) => section.type === 'intro');
  const contentSection = biteSections.find((section) => section.type === 'content');
  if (introSection?.media) {
    biteSectionTypes.push('intro');
  }

  const questionsSection = biteSections.find((section) => section.type === 'question');
  if (questionsSection?.questions.length > 0) {
    biteSectionTypes.push('question');
  }

  const summarySection = biteSections.find((section) => section.type === 'summary');

  if (summarySection?.summary_cards.length || summarySection?.media) {
    biteSectionTypes.push('summary');
  }

  if (state.bite.selectedBite.selectedBite.discussion_enabled) {
    biteSectionTypes.push('discussion');
  } else {
    if (contentSection?.content?.type === 'html') {
      biteSectionTypes.push('content');
    }
  }
  return biteSectionTypes;
};

// subtitles
export const subtitlesDisabledSelector = (state: RootState) => state.bite.subtitlesDisabled;
export const translateSubtitlesIsLoadingSelector = (state: RootState) => state.bite.translateSubtitles.isLoading;
export const translateSubtitlesTranslatingIntoLocaleSelector = (state: RootState) =>
  state.bite.translateSubtitles.translatingIntoLocale;
export const translateSubtitlesDataSelector = (state: RootState) => state.bite.translateSubtitles.data;
export const translateSubtitlesErrorSelector = (state: RootState) => state.bite.translateSubtitles.error;

// voiceovers
export const translateVoiceoversByLocaleSelector = (state: RootState) => state.bite.translateVoiceoversByLocale;
export const translateVoiceoversIsLoadingSelector = (locale: string) => (state: RootState) =>
  locale ? state.bite.translateVoiceoversByLocale[locale]?.isLoading : null;
export const translateVoiceoversPendingAudioSelector = (locale: string) => (state: RootState) =>
  locale ? state.bite.translateVoiceoversByLocale[locale]?.pendingAudio : null;
export const translateVoiceoversAudioSelector = (locale: string) => (state: RootState) =>
  locale ? state.bite.translateVoiceoversByLocale[locale]?.audio : null;
export const translateVoiceoversErrorSelector = (locale: string) => (state: RootState) =>
  locale ? state.bite.translateVoiceoversByLocale[locale]?.error : null;

export const translatedVoiceoversLocaleSelector = (state: RootState) => state.bite.translatedVoiceoversLocale;
export const alwaysTranslateToLocaleSelector = (state: RootState) => state.bite.alwaysTranslateToLocale;
export const currentBiteLocaleSelector = (state: RootState) => state.bite.currentLocale;
export const isSubtitlesTranslatedManuallySelector = (state: RootState) => state.bite.isSubtitlesTranslatedManually;
